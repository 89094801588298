<template>
  <!-- <header class="w1200"> -->
  <div
    style="
      width: 100%;
      background-color: #f2f2f2;
      position: fixed;
      top: 0px;
      right: 0px;
      left: 0px;
      z-index: 9999;
    "
  >
    <header
      class="w1200"
      style="margin-right: auto; margin-left: auto; background-color: #f2f2f2"
    >
      <div class="spbj lddq">
        <div class="cd-logo sjyc">
          <a @click="clickItem('Home')"
            ><img
              src="/public/webpic/20190705/53941c1c1edf13fcc51029e637bce0f1.png"
              alt="Logo"
          /></a>
        </div>
        <div class="spbj lddq czjz ky">
          <div class="nbj-z100 spjz">
            <input
              type="text"
              placeholder="请输入关键词"
              class="input"
              name="slt"
              v-model="searchText"
            />
            <button class="button tp-20" @click="searchArticle()">
              <img src="/public/static/index/img/ss.png" />
            </button>
          </div>
        </div>
        <!-- responsive nav bar -->
        <div class="nav-bar" ref="bar">
          <a
            ><i class="fa fa-bars"
              ><img src="/public/static/index/img/menu.png" /></i
            ><img
              src="/public/webpic/20190705/53941c1c1edf13fcc51029e637bce0f1.png"
              alt="Logo"
          /></a>
        </div>
        <!-- navigation -->
        <div class="">
          <nav class="w1200 spjz">
            <ul class="" id="nav">
              <li>
                <a @click="clickItem('News')" :class="getItemClass('News')"
                  >最新消息</a
                >
              </li>
              <li>
                <a @click="clickItem('Home')" :class="getItemClass('Home')"
                  >首页</a
                >
              </li>
              <li>
                <a @click="clickItem('AITest')" :class="getItemClass('AITest')"
                  >AI检测</a
                >
              </li>
              <li>
                <a
                  @click="clickItem('Healthy')"
                  :class="getItemClass('Healthy')"
                  >健康调理</a
                >
              </li>
              <li>
                <a
                  @click="clickItem('Article')"
                  :class="getItemClass('Article')"
                  >社区精选</a
                >
              </li>
              <li>
                <a
                  @click="clickItem('AppDownload')"
                  :class="getItemClass('AppDownload')"
                  >APP下载</a
                >
              </li>
              <li>
                <a
                  @click="clickItem('AboutUs')"
                  :class="getItemClass('AboutUs')"
                  >关于楼易</a
                >
              </li>
            </ul>
          </nav>
          <!-- <script type="text/javascript">
            var urlstr = location.href;
            console.log(urlstr + "/");
            var urlstatus = false;
            $("#nav a").each(function () {
              if (
                (urlstr + "/").indexOf($(this).attr("href")) > -1 &&
                $(this).attr("href") != ""
              ) {
                $(this).addClass("on");
                urlstatus = true;
              } else {
                $(this).removeClass("on");
              }
            });
            // if (!urlstatus) {$("#nav a").eq(0).addClass('on'); }
          </script> -->
        </div>
        <!-- navigation end -->
      </div>
    </header>
  </div>
</template>
<script>
export default {
  data() {
    return {
      searchText: this.$route.query.searchText,
    };
  },
  methods: {
    getItemClass(id) {
      if (this.$route.name == id) {
        return "on";
      } else {
        return "";
      }
    },
    clickItem(id) {
      this.$refs["bar"].click();
      this.$router.push({
        name: id,
      });
    },
    searchArticle() {
      this.$router.push({
        name: "Article",
        query: {
          searchText: this.searchText,
        },
      });
    },
  },
  watch: {
    "$route.query.searchText"(value) {
      this.searchText = value;
    },
  },
};
</script>
<style scoped>
.fa.fa-bars {
  font-size: 0;
}
</style>