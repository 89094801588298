import { ApiMethods } from "../enum/ApiMethods";
export default class ApiHelper {
    constructor() {
        throw new Error("no need instance");
    }
    public static getUrl(url: string | string, data: any | null) {
        const getParams = data ?
            (Object.keys(data)
                .filter((key) => data[key])
                .map((key) => {
                    let value = data[key];
                    switch (typeof value) {
                        case "object":
                            value = JSON.stringify(value);
                    }
                    return `${key}=${value}`
                })
                .join("&")) : "";
        return `${url}?${encodeURI(getParams)}`;
    }
    public static callSelf(method: ApiMethods, path: string, data: any = null): Promise<any> {
        return this.call(method, location.origin + process.env.MIX_ROUTE_PATH + path, data);
    }
    public static call(method: ApiMethods, url: string, data: any = null): Promise<any> {
        let apiFullUrl: string = url;
        const fetchParams: RequestInit = {
            method: method.toString(),
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, same-origin, *omit
            body: null,
            headers: {
                'user-agent': 'Mozilla/4.0 Louyiai',
                'content-type': 'application/json'
            },
            mode: 'cors', // no-cors, cors, *same-origin
            redirect: 'follow', // manual, *follow, error
            referrer: 'no-referrer', // *client, no-referrer
        }
        if (method == ApiMethods.GET) {
            apiFullUrl = this.getUrl(url, data);
            log(`${method}: ${apiFullUrl}`);
        } else {
            fetchParams.body = data ? JSON.stringify(data) : ""; // must match 'Content-Type' header
            log(`${method}: ${apiFullUrl}`);
            log(data);
        }

        return fetch(apiFullUrl, fetchParams)
            .then(response => {
                return response.json();
            })
            .then(json => {
                log(`response from: ${apiFullUrl}`);
                log(json);
                if (!json.success) {
                    throw { message: `系統忙碌中, 請稍後再試(${json.errorCode})`, errorCode: json.errorCode };
                } else if (json.errorCode != 0) {
                    throw { message: `${json.message ? json.message : '執行失敗'}(${json.errorCode})`, errorCode: json.errorCode };
                } else {
                    return json.data;
                }
            }).catch((e) => {
                if (e.name == "SyntaxError") {
                    throw new Error('系統忙碌中, 請稍後再試');
                } else {
                    throw e;
                }

            });
    }

    public static openOutsideByPath(method: ApiMethods, path: string | string, data: any | null, target: string = '_blank'): Promise<object> {

        return this.openOutside(method, location.origin + process.env.MIX_ROUTE_PATH + path, data, target);

    }
    public static openOutside(method: ApiMethods, url: string | string, data: any | null = null, target: string = '_blank'): Promise<object> {
        return new Promise((resolve, reject) => {
            let openUrl = url;
            if (method == ApiMethods.POST) {
                log(`open outside: [${method}]${url}`);
                log(data);
                this.openWindow(openUrl, method, data, target);
            } else {
                openUrl = ApiHelper.getUrl(url, data);
                log(`open outside: [${method}]${openUrl}`);
                this.openWindow(openUrl, method, null, target);
            }

            window.pageCallback = (result: any) => {
                const json = JSON.parse(decodeURIComponent(result));
                log(`response from: ${openUrl}`);
                log(json);
                if (!json.success || json.statusCode != 200) {
                    reject(json);
                } else {
                    resolve(json.data);
                }
            };
        });

    }
    public static openWindow(url: string, method: ApiMethods, data: any = null, target: string = "_blank") {
        const httpAttr = 'height = 400, width = 400, top = 0, left = 0, toolbar = yes, menubar = yes, scrollbars = yes, resizable = yes, location = yes, status = yes, noopener=no';
        if (method == ApiMethods.POST) {
            var form = document.createElement("form");
            form.target = target;
            form.method = method;
            form.action = url;
            form.style.display = "none";
            form.onsubmit = () => {
                window.open('about: blank', target, httpAttr);

            }
            for (var key in data) {
                var input = document.createElement("input");
                input.type = "hidden";
                input.name = key;
                if (typeof data[key] == 'object') {
                    input.value = JSON.stringify(data[key]);
                } else {
                    input.value = data[key];
                }
                form.appendChild(input);
            }

            document.body.appendChild(form);
            form.submit();
            document.body.removeChild(form);
        } else {
            window.open(url, target, httpAttr);
        }
    }
}
