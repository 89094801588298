<template>
  <div class="h100">
    <div class="w1200 spjz">
      <div class="czbj w-544 sxjz">
        <div class="qzhh">
          <div class="fr">
            <div class="tp-h60">
              <img src="/public/static/index/img/appxzcn.png" />
            </div>
          </div>
        </div>

        <div class="qzhh wbj-s5">
          <div class="fr">
            <div class="tp-h30">
              <img src="/public/static/index/img/appxzen.png" />
            </div>
          </div>
        </div>

        <div class="wbj-s30">
          <!-- <div class="dorp fr wbj-y10">
						<div class="tp-h50 bkyj-5 bjys-fff">
							<img src="/public/static/index/img/appewm.jpg" class="nbj-10">
						</div>
					</div> -->
          <div class="dorp fr wbj-z10">
            <div class="tp-h50 bkyj-5 bjys-fff">
              <img src="/public/static/index/img/andapp.jpg" class="nbj-10" />
            </div>
            <div class="dorp_con tp-110">
              <img src="/public/webpic/20200605/1aacf385bfcbe095ef103a39a855f2e5.png" />
            </div>
          </div>
          <div class="dorp fr wbj-zy10">
            <div class="tp-h50 bkyj-5 bjys-fff">
              <img src="/public/static/index/img/iosapp.jpg" class="nbj-10" />
            </div>
            <div class="dorp_con tp-110">
              <img src="/public/webpic/20200605/700dc0635b666222363dbba49491d0c2.png" />
            </div>
          </div>
          <div class="dorp fr">
            <div class="tp-h50 bkyj-5 bjys-fff">
              <img src="/public/static/index/img/xcxapp.jpg" class="nbj-10" />
            </div>
            <div class="dorp_con tp-110">
              <img src="/public/webpic/20200605/80db9e10cde0fa562201feb761d7850c.jpg" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="sjyc">
      <div class="bg-v">
        <video autoplay loop muted>
          <source src="/public/shipin/20190719/9fc217d71d7581e8b4a08015c4366afd.mp4"
            poster="/public/pagepic/20190729/54f4bbe3eabfa9f3d96bf214a6441495.jpg" type="video/mp4" />
        </video>
      </div>
    </div>
    <div class="sjxs" style="
        background: url(/public/pagepic/20190729/54f4bbe3eabfa9f3d96bf214a6441495.jpg)
          no-repeat center;
        height: 100%;
      "></div>
    <div class="w1200 spjz jzdq">
      <div class="gddb ys-fff">
        <div class="w-800 spjz louyi-agreement">
          <div class="spbj lddq qzdx">
            <li style="cursor: pointer;" @click="toPrivacyPolicy"><u>《楼易健康隐私协议》</u></li>
            <li style="cursor: pointer;" @click="toUserService"><u>《楼易健康用户服务协议》</u></li>
          </div>
        </div>
        <div class="w-800 spjz">
          <div class="spbj lddq qzdx">
            <li>©2019-2025</li>
            <li>楼易信息技术(上海)有限公司</li>
            <li>沪ICP备19015879号-1</li>
            <li>上海市静安区共和路219号企业公馆2203A室</li>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toPrivacyPolicy() {
      this.$router.push({
        name: "PrivacyPolicy",
      });
    },
    toUserService() {
      this.$router.push({
        name: "UserService",
      });
    },
  },
};
</script>
<style type="text/css">
.h100 {
  height: 100vh;
  background-size: auto 100%;
  bottom: 0;
}
.dorp {
  margin: 10px;
  z-index: auto;
}
.dorp_con {
  z-index: 1;
}
.louyi-btn {
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}
.louyi-modal .ivu-modal {
  max-width: 1200px !important;
}
.louyi-modal .modal-body {
  padding: 0 20px;
}
.louyi-modal .modal-body p {
  font-size: 16px;
  margin-bottom: 13px;
  font-family: tahoma;
}
.louyi-modal .modal-body label {
  font-size: 16px;
  width: 100%;
  font-family: tahoma;
  margin-bottom: 0;
  line-height: 1.4;
}
.louyi-modal .modal-body .line-left {
  padding-left: 31px;
}
.louyi-modal .modal-body .line-left.no-padding-right {
  padding-right: 0;
}
.louyi-modal .louyi-text-indent {
  margin-left: 41px;
  text-indent: -41px;
  padding-right: 41px;
}
.louyi-modal .louyi-text-indent.big-text {
  margin-left: 81px;
  text-indent: -81px;
  padding-right: 81px;
}
.louyi-modal ul,
.louyi-modal li {
  list-style: decimal;
  font-family: tahoma;
  font-size: 16px;
}
.louyi-modal ul {
  margin-left: 27px;
}
.louyi-modal li {
  padding-bottom: 5px;
}
.louyi-modal .ivu-btn > span {
  color: #000;
  font-size: 16px;
}
.w-800.spjz.louyi-agreement {
  max-width: 350px;
  margin: 0 auto;
}

@media screen and (max-width: 480px) {
  .louyi-modal .modal-body {
    padding: 0;
  }
  .louyi-modal .modal-body .line-left {
    padding-left: 10px;
  }
  .w-800.spjz.louyi-agreement {
    max-width: 100%;
    margin: auto;
    text-align: left;
  }
}
</style>
