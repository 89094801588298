<template>
  <div>
    <!-- 轮播图 -->
    <div class="slider5 tp100 cd-main-content">
      <li>
        <img src="/public/webpic/20190916/cb17d04f00a4ba95d4fc4464f19c469e.jpg" />
      </li>
    </div>
    <!-- 视频专区 -->

    <div class="fgx-30"></div>
    <div class="wbj-s30 wow a-fadeinB" id="Videos">
      <div class="w1200 spjz wzjz">
        <div class="ys-fff zt46 ztjc qzdx">Popular Videos</div>
        <div class="ztjc wbj-s-25 zt22">视频专区</div>
        <div class="fgx-30"></div>
        <div class="fgx-1 spjz w-400 bjys-99"></div>
        <div class="wbj-s-15 qzdx">
          <li class="w-160 spjz ys-666 zt22 bjys-f2">INTRODUCE</li>
        </div>
        <div class="wzky wbj-x20"></div>
      </div>
    </div>

    <div class="wbj-x20 w1200 spjz">
      <div v-if="popularVideos.length" class="spbj bjhh" id="shipinres">
        <div v-for="video in popularVideos" :key="video.id" class="nbj-sx20 w33 czbj wow a-fadeinB"
          data-wow-delay="0.3s">
          <a @click="clickItem(video)" class="xfyy bjys-fff">
            <div class="tp-h300">
              <img :src="video.preview_img" />
            </div>
            <div class="bjys-fff nbj-5-10 nbj-x10">
              <div class="zt16 ztjc lh-30" v-html="getLang(video,'title')"></div>
              <div class="ys-666 h-30 lh-30 qzhh" v-html="getLang(video,'description')"></div>
              <li class="ys-666">{{ video.timestamp }}</li>
            </div>
          </a>
        </div>
      </div>
      <div v-else style="text-align: center">暂无搜索结果</div>
    </div>

    <!-- 加载更多 -->

    <!-- <div class="wbj-sx30 wow a-fadeinB">
      <a
        href="javascript:getshipin(1);"
        id="shipinabq"
        class="btn2 spjz wzjz zt16 nbj-5-10"
        >加载更多</a
      >
    </div> -->

    <div class="fgx-10"></div>

    <!-- 精选文章 -->
    <div class="wbj-s30" id="Articles">
      <div class="w1200 spjz wzjz wow a-fadeinB">
        <div class="ys-fff zt46 ztjc qzdx">Popular articles</div>
        <div class="ztjc wbj-s-25 zt22">文章专区</div>
        <div class="fgx-30"></div>
        <div class="fgx-1 spjz w-400 bjys-99"></div>
        <div class="wbj-s-15 qzdx">
          <li class="w-160 spjz ys-666 zt22 bjys-f2">INTRODUCE</li>
        </div>
        <div class="wzky wbj-x20"></div>
      </div>
    </div>

    <div class="wbj-s-10">
      <div class="w1200 spjz">
        <div class="spbj bjhh" id="articleres">
          <div v-for="article in popularArticles" :key="article.id" class="w25 h-450 czbj nbj-sx20 wow a-fadeinB"
            data-wow-delay="0.3s">
            <a @click="clickItem(article)" class="xsyd xfbk">
              <div class="tp-h300">
                <img :src="article.preview_img" />
              </div>
              <div class="bjys-fff nbj-5-10 nbj-x10">
                <div class="zt16 ztjc lh-30" v-html="getLang(article,'title')"></div>
                <div class="fgx-3 w-20 bjys-0a"></div>
                <div class="ys-666 h-30 lh-30 qzhh" v-html="getLang(article,'description')"></div>
                <div class="spbj lddq nbj-s10">
                  <li class="ys-666">{{ article.timestamp }}</li>
                  <li class="ys-0a ztjc">more>></li>
                </div>
              </div>
            </a>
          </div>
          <div v-if="!popularArticles.length" style="margin: auto">
            暂无搜索结果
          </div>
        </div>
      </div>
    </div>

    <!-- 加载更多 -->

    <!-- <div class="wbj-sx30 wow a-fadeinB">
      <a
        href="javascript:getarticle(2);"
        id="articleabq"
        class="btn2 spjz wzjz zt16 nbj-5-10"
        >加载更多</a
      >
    </div> -->

    <div class="fgx-30"></div>
  </div>
</template>
<script>
export default {
  props: {
    moveToID: {
      type: String,
      default: "",
    },
  },
  mounted() {
    $(".slider5").bxSlider({
      mode: "fade",
      controls: false,
      auto: false,
      autoControls: false,
      slideMargin: 10,
      pager: false,
    });
    if (this.moveToID) {
      scrollToID(this.moveToID);
    }
  },
  data() {
    return {};
  },
  computed: {
    articles() {
      return copy(this.$store.state.articles);
    },
    popularVideos() {
      const popularVideos = this.articles.filter(
        (article) => article.category == "other" && article.type == "video"
      );
      if (this.$route.query.searchText) {
        return popularVideos.filter((article) => {
          let name = this.getLang(article, "title");
          if (name.indexOf(this.$route.query.searchText) >= 0) {
            name = name.replaceAll(
              this.$route.query.searchText,
              `<font class="zt16" color="red">${this.$route.query.searchText}</font>`
            );
            article.title = name;
            article.cn_title = name;
            article.en_title = name;
            return true;
          }
          return false;
        });
      }
      return popularVideos;
    },
    popularArticles() {
      const allowCategories = ["featured", "beauty", "healthy"];
      const popularArticles = this.articles.filter(
        (article) => allowCategories.indexOf(article.category) >= 0
      );
      if (this.$route.query.searchText) {
        return popularArticles.filter((article) => {
          let name = this.getLang(article, "title");
          if (name.indexOf(this.$route.query.searchText) >= 0) {
            name = name.replaceAll(
              this.$route.query.searchText,
              `<font class="zt16" color="red">${this.$route.query.searchText}</font>`
            );
            article.title = name;
            article.cn_title = name;
            article.en_title = name;
            return true;
          }
          return false;
        });
      }
      return popularArticles;
    },
  },
  methods: {
    //取得語系內容
    getLang(row, type) {
      return getArticleLang(row, type);
    },
    clickItem(article) {
      this.$router.push({
        name: "ArticleDetail",
        params: { id: article.id, article },
      });
    },
  },
  watch: {
    "$route.query.searchText"(value) {},
  },
};
</script>
