<template>
  <div class="privacy-component">
    <h4 style="text-align: center;font-weight: bold;">楼易健康隐私协议</h4>
    <div>
      <div class="modal-body">
        <p>
          楼易健康将严格保守用户的个人隐私，承诺未经用户同意不得将其个人信息任意披露。但在本隐私条款第一条第（四）款第1至11项以及第四条第（三）款第1至6项的情形下楼易健康无法再提供前述保证而披露您的相关信息。楼易健康收集、使用个人信息，遵循合法、正当、必要的原则，公开收集、使用规则，明示收集、使用信息的目的、方式和范围，并经被收集者同意。
        </p>
        <p>（1）为了保障产品的正常运行，实现个性化推荐、发布信息、互动交流、搜索查询等核心功能以及其他功能，我们会收集你的部分必要信息；</p>
        <p>
          （2）在你进行注册登录、发布信息、互动交流或使用基于地理位置的服务时，基于法律要求或实现功能所必须，我们可能会收集出生日期、性别、地理位置、通讯录等个人敏感信息。你有权拒绝向我们提供这些信息，或者撤回你对这些信息的授权同意。请你了解，拒绝或撤回授权同意，将导致你无法使用相关的特定功能；
        </p>
        <p>（3）我们会将在运营过程中收集和产生的你的个人信息存储于中华人民共和国境内，并不会将上述信息传输至境外。我们仅会在为提供楼易健康及服务之目的所必需的期间内保留你的个人信息；</p>
        <p>（4）我们不会向第三方共享、提供、转让或者从第三方获取你的个人信息，除非经过你的同意；</p>
        <p>（5）我们将努力采取合理的安全措施来保护你的个人信息。特别的，我们将采用行业内通行的方式及尽最大的商业努力来保护你个人敏感信息的安全；</p>
        <p>（6）楼易健康会基于算法等人工智能决策机制，推送你可能感兴趣的信息，你可以通过点击推送信息中的取消按键，选择屏蔽相关信息；</p>
        <p>（7）你访问、更正、删除个人信息与撤回同意授权的方式，以及注销账号、投诉举报的方式。</p>
        <p style="font-weight: bold;">
          如你未明示同意本隐私政策并开始使用，我们将仅收集浏览推荐所必需的信息，这将导致我们无法为你提供完整的产品和服务，但我们同样会采用去标识化、加密等措施来保护这些信息。如果你不同意本隐私政策，我们无法为你提供完整的产品和服务，你也可以选择停止使用。当你选择继续或再次使用时，基于提供产品和服务所必需，将视为你接受和认可我们按照本政策对你的相关信息进行处理。
        </p>
        <p>
          楼易健康（以下简称“我们”）深知个人信息对你的重要性，我们将按照法律法规的规定，采用安全的保护措施，保护你的个人信息及隐私安全。因此，我们制定本“隐私政策”并特别提示：希望你在使用楼易健康及相关服务前仔细阅读并理解本隐私政策，你可以按照指引作出你的适当选择。
        </p>
        <p>
          我们希望通过本隐私政策向你说明在你在使用楼易健康软件及相关服务时，我们将按照本隐私政策收集、使用、存储、共享、转让及保护你的个人信息，向你介绍我们对你个人信息的处理，以及我们为你提供的查询、访问、删除、更正、撤回授权同意和保护这些信息的方式。
        </p>
        <p style="font-weight: bold;margin-bottom: 0;">本隐私政策将帮助你了解以下内容：</p>
        <p class="line-left">
          <label>一、我们如何收集和使用个人信息</label><br>
          <label>二、我们对 Cookie 和同类技术的使用</label><br>
          <label>三、我们如何存储个人信息</label><br>
          <label>四、我们如何共享、转让、公开披露个人信息</label><br>
          <label>五、我们如何保护个人信息的安全</label><br>
          <label>六、您如何管理您的个人信息</label><br>
          <label>七、隐私政策的修订和通知</label><br>
          <label>八、适用范围</label><br>
          <label>九、其他</label>
        </p>
        <p style="font-weight: bold;margin-bottom: 0;">一、我们如何收集和使用个人信息</p>
        <label class="line-left">
          <p>
            <label>在你使用楼易健康及服务的过程中，我们将根据合法、正当、必要的原则，收集信息。我们收集或请你提供的信息将用于：</label>
            <label class="line-left">
              <label>1.保障产品的正常基础运行；</label><br>
              <label>2.实现各项功能和服务；</label><br>
              <label>3.优化、改善产品和服务；</label><br>
              <label>4.保障产品、服务以及用户使用安全；</label><br>
              <label>5.遵循法律法规与国家标准的规定。</label>
            </label>
          </p>
          <p>
            <label style="font-weight: bold;">（一）我们主动收集与使用的个人信息</label>
            <label class="line-left">我们会按照如下方式收集你在使用服务时主动提供的，以及你在使用功能或接受服务过程中产生的信息：</label>
          </p>
          <p>
            <label class="line-left">
              <label style="font-weight: bold;">1.保障楼易健康和相关服务的正常运行</label>
              <label
                class="line-left">当你使用楼易健康及相关服务时，为了保障软件与服务的正常运行，我们会收集你的硬件型号、操作系统版本号、国际移动设备识别码（IMEI）、网络设备硬件地址（MAC）、IP
                地址、软件版本号、网络接入方式及类型、操作日志等信息。请你了解，这些信息是我们提供服务和保障产品正常运行所必须收集的基本信息。</label>
            </label>
          </p>
          <p>
            <label class="line-left">
              <label style="font-weight: bold;">2.注册、登录楼易健康和相关服务</label>
              <p>
                <label
                  class="line-left louyi-text-indent">（1）当你注册、登录楼易健康及相关服务时，你可以通过手机号创建账号，我们将通过发送短信验证码来验证你的身份是否有效，并且你可完善相关的网络身份识别信息（如头像、昵称和出生日期），收集这些信息是为了帮助你完成注册。</label>
              </p>
              <label
                class="line-left louyi-text-indent">（2）你也可以使用第三方账号（如微信、QQ、微博等）登录进入楼易健康，你此时将授权我们获取你在第三方平台注册的公开信息（头像、昵称等），并在你同意本隐私政策后将你的第三方账号与你的楼易健康账号绑定，使你可以通过第三方账号直接登录并使用本产品和相关服务。</label>
            </label>
          </p>
          <p>
            <label class="line-left">
              <label style="font-weight: bold;">3.为你推荐个性化的信息或服务</label>
              <p><label class="line-left">个性化推荐是楼易健康最基础、最核心的功能之一。 为实现这一功能，我们可能会收集并使用下列信息：</label></p>
              <p><label class="line-left louyi-text-indent">（1）搜索、浏览偏好（你感兴趣的内容、音视频等信息）等你的操作、使用行为信息；</label></p>
              <p><label class="line-left louyi-text-indent">（2）反馈、发布、点赞、评论等你主动提供的信息；</label></p>
              <p><label class="line-left louyi-text-indent">（3）获得你的明示同意后的地理位置信息。</label></p>
              <label class="line-left">请你了解，我们收集、使用的上述信息进行了去标识化处理，数据分析仅对应特定的、无法直接关联用户身份的编码，无法也绝不会与你的真实身份相关联。</label>
            </label>
          </p>
          <p>
            <label class="line-left">
              <label style="font-weight: bold;">4.为你提供信息发布功能或服务</label>
              <p><label class="line-left">你发布内容、评论、提问或回答时，我们将收集你发布的信息，并展示你的昵称、头像、发布内容等。</label></p>
              <p><label
                  class="line-left louyi-text-indent">（1）你使用上传图片、发布音视频功能时，我们会请求你授权相机、照片、麦克风等敏感权限。你如果拒绝授权提供，将无法使用此功能，但不影响你正常使用楼易健康的其他功能。</label>
              </p>
              <label class="line-left louyi-text-indent">（2）你发布信息并选择显示位置时，我们会请求你授权地理位置这一敏感权限，并收集与本服务相关的位置信息。这些技术包括 IP
                地址、GPS 以及能够提供相关信息的 WI-FI 接入点、蓝牙和基站等传感器技术。你如果拒绝授权提供，将无法使用此功能，但不影响你正常使用楼易健康的其他功能。</label>
            </label>
          </p>
          <p>
            <label class="line-left">
              <label style="font-weight: bold;">5.为你提供互动功能或服务</label>
              <p><label
                  class="line-left louyi-text-indent">（1）你使用推荐通讯录好友功能时，在获得你的明示同意后，我们会将你通讯录中的信息进行高强度加密算法处理后，用于向你推荐通信录中的好友。</label>
              </p>
              <label class="line-left">通讯录信息属于个人敏感信息，拒绝提供该信息仅会使你无法使用上述功能，但不影响你正常使用楼易健康及相关服务的其他功能。</label>
            </label>
          </p>
          <p>
            <label class="line-left">
              <label style="font-weight: bold;">6.为你提供搜索服务</label>
              <label
                class="line-left">你使用楼易健康的搜索服务时，我们会收集你的搜索关键字信息等。为了提供高效的搜索服务，部分前述信息会暂时存储在你的本地存储设备之中，并可向你展示搜索结果内容、搜索近期历史记录。</label>
            </label>
          </p>
          <p>
            <label class="line-left">
              <label style="font-weight: bold;">7.向您提供商品或服务</label>
              <label class="line-left">您向我们提供的信息<br>
                为便于向您交付商品或服务，您需提供收货人姓名、收货地址、邮政编码、收货人联系电话，部分商品是由和我们合作的第三方提供，如果您拒绝提供此类信息，将无法完成相关交付服务
              </label>
            </label>
          </p>
          <p>
            <label class="line-left">
              <label style="font-weight: bold;">8.开展营销活动</label>
              <label
                class="line-left">当你选择参加我们举办的有关营销活动时，根据活动需要你可提供姓名、通信地址、联系方式、支付宝账号等信息。这些信息可能包括个人敏感信息（如个人电话号码、支付宝账号等），是你收到转账或者礼品所必要的，如果你拒绝提供这些信息，我们将可能无法向你转账或发放礼品。</label>
            </label>
          </p>
          <p>
            <label class="line-left">
              <label style="font-weight: bold;">9.保障产品、服务及用户使用安全</label>
              <label
                class="line-left">为帮助我们更好地了解楼易健康及相关服务的运行情况，以便确保运行与提供服务的安全，我们可能记录网络日志信息，以及使用软件及相关服务的频率、崩溃数据、总体安装、使用情况、性能数据等信息。</label>
            </label>
          </p>
          <p>
            <label>
              <label style="font-weight: bold;">（二）我们可能从第三方获得的你的个人信息</label>
              <p><label class="line-left">1.当你使用第三方账号登录楼易健康时，经过你的明示同意，我们会收集第三方账号的昵称、头像等信息。</label></p>
              <label
                class="line-left">2.用户因使用我们的产品或者服务而被我们收集的信息，例如其他用户发布的信息中可能含有你的部分信息（如：在评论、留言、发布图文涉及到与你相关的信息）。</label>
            </label>
          </p>
          <p>
            <label>
              <label style="font-weight: bold;">（三）收集、使用个人信息目的变更的处理</label>
              <label
                class="line-left">请你了解，随着我们业务的发展，可能会对楼易健康的功能和提供的服务有所调整变化。原则上，当新功能或服务与个性化推荐、发布信息、互动交流、搜索查询、注册认证等场景相关时，收集与使用的个人信息将与原处理目的具有直接或合理关联。在与原处理目的无直接或合理关联的场景下，我们收集、使用你的个人信息，会再次进行告知，并征得你的同意。</label>
            </label>
          </p>
          <p>
            <label>
              <label style="font-weight: bold;">（四）依法豁免征得同意收集和使用的个人信息</label>
              <label class="line-left">在下列情形中，根据法律法规及相关国家标准，我们收集和使用你的个人信息无需征得你的授权同意：</label>

              <p>
                <label class="line-left">
                  <ul style="list-style-type:decimal;">
                    <li>与国家安全、国防安全直接相关的；</li>
                    <li>与公共安全、公共卫生、重大公共利益直接相关的；</li>
                    <li>与犯罪侦查、起诉、审判和判决执行等直接相关的；</li>
                    <li>出于维护本APP用户或其他个人、集体和国家等重大合法权益但又很难得到本人同意的；</li>
                    <li>所收集的你的个人信息是你自行已向社会公众公开的；</li>
                    <li>从合法公开披露的信息中收集的你的个人信息的，如合法的新闻报道、政府信息公开等渠道；</li>
                    <li>根据你的要求签订或履行合同所必需的；</li>
                    <li>用于维护楼易健康软件及相关服务的安全稳定运行所必需的，例如发现、处置楼易健康软件及相关服务的故障；</li>
                    <li>为合法的新闻报道所必需的；</li>
                    <li>学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</li>
                    <li>法律法规规定的其他必须披露或公开的情形。</li>
                  </ul>
                </label>
              </p>
              <label
                class="line-left">无论当你的信息可以单独或结合其他信息识别到你的个人身份还是你的信息单独或结合其他信息无法识别到你的个人身份，这些信息在结合使用期间，都将作为你的个人信息按照本隐私政策处理与保护。</label>
            </label>
          </p>
        </label>

        <p style="font-weight: bold;margin-bottom: 0;">二、对 Cookie 和同类技术的使用</p>
        <label>
          <p><label class="line-left">Cookie 和同类技术是互联网中的通用常用技术。当你使用楼易健康及相关服务时，我们可能会使用相关技术向你的设备发送一个或多个 Cookie
              或匿名标识符，以收集和存储你访问、使用本产品时的信息。我们使用 Cookie 和同类技术主要为了实现以下功能或服务：</label></p>
          <p><label class="line-left louyi-text-indent">（一）保障产品与服务的安全、高效运转<br>我们可能会设置认证与保障安全性的 cookie
              或匿名标识符，使我们确认你是否安全登录服务，或者是否遇到盗用、欺诈等不法行为。这些技术还会帮助我们改进服务效率，提升登录和响应速度。</label></p>
          <p><label
              class="line-left louyi-text-indent">（二）帮助你获得更轻松的访问体验<br>使用此类技术可以帮助你省去重复你填写个人信息、输入搜索内容的步骤和流程（例如：记录搜索历史）。</label>
          </p>
          <p><label class="line-left louyi-text-indent">（三）为你推荐、展示、推送你可能感兴趣的内容或账号<br>我们可能会利用 Cookie 和同类技术（例如
              Beacon、Proxy 等技术）了解你的偏好和使用习惯，进行咨询或数据分析，以改善产品服务及用户体验，并优化你对广告的选择。 我们承诺，我们不会将 Cookie
              用于本隐私政策所述目的之外的任何其他用途。</label></p>
        </label>

        <p style="font-weight: bold;margin-bottom: 0;">三、我们如何存储个人信息</p>
        <label>
          <p><label
              class="line-left louyi-text-indent">（一）信息存储的地点<br>我们依照法律法规的规定，将在境内运营过程中收集和产生的你的个人信息存储于中华人民共和国境内。目前，我们不会将上述信息传输至境外，如果我们向境外传输，我们将会遵循法律法规的规定，征求你的自主选择同意。</label>
          </p>
          <p class="line-left">
            <label>（二）存储期限</label>
            <label class="line-left">
              <ul style="list-style-type:decimal;">
                <li>
                  我们仅在为提供楼易健康及服务之目的所必需的期间内保留你的个人信息，例如，你发布的信息、评论、点赞等信息，在你未删除或未注销账号期间，我们会保留相关信息，保留期限为用户主账号到期后一年。超出期限的，我们将对你的个人信息进行删除或匿名化处理，但法律法规另有规定的除外。
                </li>
                <li>用户注册楼易健康帐号后如果在会员付费相关服务结束后且不再进行会员服务资格购买的话，楼易有权回收该帐号以免造成资源浪费，由此带来的任何损失均由用户自行承担。</li>
                <li>如果你停止使用本软件及服务或服务被终止或取消，楼易可以从服务器上永久地删除你的数据。服务停止、终止或取消后，楼易没有义务向你返还任何数据。</li>
              </ul>
            </label>
          </p>
        </label>

        <p style="font-weight: bold;margin-bottom: 0;">四、我们如何共享、转让、公开披露个人信息</p>
        <label>
          <p><label
              class="line-left louyi-text-indent">（一）个人信息的共享、转让<br>我们不会向第三方共享、转让你的个人信息，除非经过你本人事先授权同意，或者共享、转让的个人信息是去标识化处理后的信息，且共享第三方无法重新识别此类信息的自然人主体。</label>
          </p>
          <p>
            <label class="line-left">
              <p><label>1、在下列情况下，经过你的自主选择同意，我们可能会共享的个人信息</label></p>
              <p><label
                  class="line-left louyi-text-indent">（1）为实现相关功能或服务与关联方共享<br>当你在使用APP所有功能时，为保障你在我们及关联方提供的产品间所接受服务的一致性，并方便统一管理你的信息，我们可能会将你的个人信息与这些关联方共享。</label>
              </p>
              <p><label
                  class="line-left louyi-text-indent">（2）为实现特定功能而与业务合作伙伴共享<br>当软件服务提供商、智能设备提供商或系统服务提供商与我们联合为你提供服务时，例如你需要使用地理位置功能时，为实现这一功能，我们可能会收集你的位置信息及相关设备信息（例如硬件型号、操作系统版本号、国际移动设备身份识别码（IMEI）、网络设备硬件地址（MAC））经过去标识化后并提供给前述提供商。</label>
              </p>
              <p><label
                  class="line-left louyi-text-indent">（3）帮助你参加营销推广活动<br>当你选择参加我们举办的有关营销活动时，根据活动需要你提供姓名、通信地址、联系方式、支付宝账号等信息。经过你的明示同意，我们会将上述信息与第三方共享以便我们能委托第三方及时向你提供奖品。个人信息与这些关联方共享。</label>
              </p>
            </label>
            <label class="line-left">
              <p><label
                  class="louyi-text-indent">2、对共享个人信息第三方主体<br>我们只会与第三方共享实现目的所必要的信息。（例如在您购买我们产品时，我们必须与供货商、物流服务提供商共享您的个人信息，才能安排送货，我们仅会出于正当、合法、必要、特定、明确的目的共享您的个人信息。我们的合作伙伴无权将共享的个人信息用于与产品或服务无关的其他用途。）</label>
              </p>
            </label>
            <label class="line-left">
              <p><label
                  class="louyi-text-indent">3、收购、兼并、重组时个人信息的转让<br>随着我们业务的持续发展，我们将有可能进行合并、收购、资产转让，你的个人信息有可能因此而被转移。在发生前述变更时，我们将按照法律法规及不低于本隐私政策的安全标准要求个人信息的继受方继续保护你的个人信息，否则我们将要求继受方重新征得你的同意。</label>
              </p>
            </label>
          </p>
          <p>
            <label class="line-left">
              <label
                class="louyi-text-indent">（二）个人信息的公开披露<br>我们不会公开披露你的信息，除非遵循国家法律法规规定或者获得你的同意。我们公开披露你的个人信息会采用符合行业内标准的安全保护措施，具体如下：</label>
              <label class="line-left">
                <ul>
                  <li>相关主体对违规账号、欺诈行为进行处罚公告时，我们会披露相关账号的必要信息。</li>
                  <li>获得您的明确同意或基于您的主动选择，我们可能会公开披露您的个人信息。</li>
                </ul>
              </label>
            </label>
          </p>
          <p>
            <label class="line-left">
              <label
                class="louyi-text-indent">（三）依法豁免征得同意共享、转让、公开披露的个人信息<br>请你理解，在下列情形中，根据法律法规及国家标准，我们共享、转让、公开披露你的个人信息无需征得你的授权同意：</label>
              <label class="line-left">
                <ul>
                  <li>与国家安全、国防安全直接相关的；</li>
                  <li>与公共安全、公共卫生、重大公共利益直接相关的；</li>
                  <li>与犯罪侦查、起诉、审判和判决执行等直接相关的；</li>
                  <li>出于维护你或其他个人、集体和国家等重大合法权益但又很难得到本人同意的；</li>
                  <li>你自行向社会公众公开的个人信息；</li>
                  <li>从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</li>
                </ul>
              </label>
            </label>
          </p>
        </label>

        <p style="font-weight: bold;margin-bottom: 0;">五、我们如何保护个人信息的安全</p>
        <p>
          <label class="line-left">
            <p class="louyi-text-indent">
              （一）我们非常重视你个人信息的安全，将努力采取合理的安全措施（包括技术方面和管理方面）来保护你的个人信息，防止你提供的个人信息被不当使用或未经授权的情况下被访问、公开披露、使用、修改、损坏、丢失或泄漏。</p>
            <p class="louyi-text-indent">（二）我们会使用匿名化处理等合理可行的手段保护你的个人信息，并使用安全保护机制防止你的个人信息遭到恶意攻击。</p>
            <p class="louyi-text-indent">（三）我们采取严格的数据使用和访问制度，确保只有授权人员才可访问你的个人信息，并适时对数据和技术进行安全审计。</p>
            <p class="louyi-text-indent">
              （四）尽管已经采取了上述合理有效措施，并已经遵守了相关法律规定要求的标准，但请你理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全，我们将尽力确保你提供给我们的个人信息的安全性。你知悉并理解，你接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。因此，我们强烈建议你采取积极措施保护个人信息的安全，包括但不限于使用复杂密码、不将自己的账号密码等个人信息透露给他人。
            </p>
            <p class="louyi-text-indent">
              （五）我们会制定应急处理预案，并在发生用户信息安全事件时立即启动应急预案，努力阻止该等安全事件的影响和后果扩大。一旦发生用户信息安全事件（泄露、丢失等）后，我们将按照法律法规的要求，及时向你告知：安全事件的基本情况和可能的影响、我们已经采取或将要采取的处置措施、你可自主防范和降低风险的建议、对你的补救措施等。我们将及时将事件相关情况以推送通知、邮件、短信等形式告知你，难以逐一告知时，我们会采取合理、有效的方式发布公告。同时，我们还将按照相关监管部门要求，上报用户信息安全事件的处置情况。
            </p>
            <p class="louyi-text-indent">
              （六）我们谨此特别提醒你，本隐私政策提供的个人信息保护措施仅适用于楼易健康及相关服务。一旦你离开楼易健康及相关服务，浏览或使用其他网站、服务及内容资源，我们即没有能力及义务保护你在楼易健康及相关服务之外的软件、网站提交的任何个人信息，无论你登录、浏览或使用上述软件、网站是否基于楼易健康的链接或引导。
            </p>
          </label>
        </p>

        <p style="font-weight: bold;margin-bottom: 0;">六、您如何管理您的个人信息</p>
        <p>
          <label class="line-left">
            <p class="louyi-text-indent">（一）访问您的个人信息<br>您有权访问您的个人信息，法律规定的例外情况除外。你可以通过以下方式自行访问您的个人信息：</p>
            <label class="line-left">
              <label>账户信息</label>
              <label>1. 访问个人信息：</label>
              <label
                class="louyi-text-indent big-text">个人信息：你可以查询、访问、更正你的头像、昵称、出生地、居住地等基本信息，你可以在楼易健康的“个人信息”中进行查询、访问、更正。因软件的特殊性，性别、生日及血型信息共只能修改一次。</label>
              <label class="louyi-text-indent big-text">搜索信息：你可以点击搜索栏清空您的历史搜索记录</label>
              <label class="louyi-text-indent big-text">订单信息：您可以在软件中查阅您的订单记录交易记录等</label>
              <p>
                <label>2.删除评论内容</label>
                <label class="louyi-text-indent big-text">评论内容：你可通过访问单条评论内容、并通过访问页面删除你的评论。</label>
                <label>特别提示你注意，出于安全性和身份识别的考虑，你可能无法自主修改注册时提交的某些初始注册信息。</label>
              </p>
            </label>

            <p>
              <label
                class="louyi-text-indent">（二）自主选择控制个性化推荐信息<br>我们的相关信息推荐等功能可能会根据信息系统、算法等在内的非人工自动决策机制做出，当你对我们推送的信息不感兴趣或希望减少某些信息推荐时，你可以点击设置接口的消息设定，进行屏蔽。</label>
              <label style="padding-top:5px;margin-left: 41px;padding-right: 41px;">你也可以通过登录楼易健康客户端中的“意见反馈”页面，”我的”界面里“意见反馈”入口与我们联系。</label>
            </p>

            <p>
              <label class="louyi-text-indent">（三）改变你授权同意范围</label>
              <label class="line-left">
                <ul>
                  <li>改变或撤回敏感信息权限<br>你可以通过的楼易健康中删除信息、更改隐私设置以及在设备本身操作系统中关闭地理位置、通讯录、摄像头、麦克风等权限改变同意范围或撤回你的授权。</li>
                  <li>关闭签到动态<br>你可以通过设置关闭或打开“签到动态“<br>
                    <label
                      style="padding-top:5px;">请你理解，特定的业务功能和服务将需要你的信息才能得以完成，当你撤回同意或授权后，我们无法继续为你提供所对应的功能和服务，也不再处理你相应的个人信息。但你撤回同意或授权的决定，不会影响公司此前基于你的授权而开展的个人信息处理。</label>
                  </li>
                </ul>
              </label>
            </p>

            <p><label class="louyi-text-indent">（四）访问隐私政策<br>你可以在“设置”—“隐私政策”，查看本隐私政策的全部内容</label></p>
            <p><label
                class="louyi-text-indent">（五）停止运营向你告知<br>如我们停止运营，我们将及时停止收集你个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知你，并对所持有的你的个人信息进行删除或匿名化处理。</label>
            </p>
          </label>
        </p>

        <p style="font-weight: bold;margin-bottom: 0;">七、当前使用之第三方渠道</p>
        <p>
          <label class="line-left">
            <label>当前应用中集成了：</label>
            <label class="line-left">
              <ul>
                <li>腾讯移动分析（com.tencent.stat）已经过用户同意后记录用户在APP内的相关操作；</li>
                <li>华为推送（com.huawei.hms）已经过用户同意后向用户发送通知；</li>
                <li>TBS腾讯浏览服务;腾讯浏览服务;腾讯X5浏览器;腾讯浏览器（com.tencent.smtt）已完成小程序内浏览内容使用；</li>
                <li>七鱼（com.qiyukf.unicorn）已向用户提供客服服务；</li>
                <li>腾讯（Bugly com.tencent.bugly）已经过用户同意后记录用户于APP内的相关操作。</li>
              </ul>
            </label>
          </label>
        </p>

        <p style="font-weight: bold;margin-bottom: 0;">八、隐私协议的修订和通知</p>
        <p>
          <label class="line-left">
            <p><label class="louyi-text-indent">（一）为给你提供更好的服务，楼易健康及相关服务将不时更新与变化，我们会适时对本隐私政策进行修订，这些修订构成本隐私政策的一部分。</label>
            </p>
            <p><label
                class="louyi-text-indent">（二）本隐私政策更新后，我们会在楼易健康发出更新版本，如你继续使用我们的服务，表示同意接受修订后的本政策的内容，但是如果更新的内容需要采集音视频、获取你通讯录、联系方式、地理位置等个人敏感信息，仍会再次以显着方式征求你的同意。</label>
            </p>
            <p><label
                class="louyi-text-indent">（三）对于重大变更，我们还会提供更为显着的通知（我们会通过包括但不限于邮件、短信、私信或在浏览页面做特别提示等方式，说明《隐私政策》的具体变更内容）。</label>
            </p>
            <label>本《隐私政策》所指的重大变更包括但不限于：</label>
            <label class="line-left">
              <ul>
                <li>我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息的类型、个人信息的使用方式等；</li>
                <li>我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有变更等；</li>
                <li>个人信息共享、转让或公开披露的主要对象发生重大变化；</li>
                <li>你参与个人信息处理方面的权利及其行使方式发生重大变化；</li>
              </ul>
            </label>
          </label>
        </p>

        <p style="font-weight: bold;margin-bottom: 0;">九、适用范围</p>
        <p>
          <label class="line-left">
            <label>本隐私政策适用于由上海楼易信息技术有限公司及其关联方提供的所有服务，不适用于有单独的隐私政策且未纳入本隐私政策的第三方产品或服务。</label>
            <label>本隐私权政策不适用于：</label>
            <label class="line-left">
              <p><label>（一）其他第三方产品或服务，可能包括推荐中向你显示的产品或楼易健康服务中链接到的其他产品或网站；</label></p>
              <p><label
                  class="louyi-text-indent">（二）为楼易健康服务进行广告宣传的其他第三方<br>你使用这些第三方服务（包括你向这些第三方提供的任何个人信息），将受这些第三方的服务条款及隐私政策约束（而非本隐私政策），具体规定请你仔细阅读第三方的条款。请你妥善保护自己的个人信息，仅在必要的情况下向第三方提供。</label>
              </p>
            </label>
            <p>
              <label>请你了解，本隐私政策中所述的楼易健康及相关服务可能会根据你所使用的手机型号、系统版本、软件应用程序版本、移动客户端等因素而有所不同。最终的产品和服务以你所使用的楼易健康软件及相关服务为准。</label>
            </p>
            <p><label>如对本隐私政策内容有任何疑问、意见或建议，你可通过登录楼易健康客户端内的“意见反馈”页面，首页“消息—意见反馈”入口与我们联系。</label></p>
          </label>
        </p>

        <p style="font-weight: bold;margin-bottom: 0;">十、其他</p>
        <p>
          <label class="line-left">
            <p><label class="louyi-text-indent">（一）本“隐私政策”中的标题仅为方便及阅读而设，并不影响本《隐私政策》中任何规定的含义或解释。</label></p>

            <label class="louyi-text-indent">（二）本“隐私政策”相关词语释义：</label>
            <label class="line-left">
              <ul>
                <li>楼易健康是指：楼易健康指的是目前APP暂定的名称，后续会调整。</li>
                <li>
                  关联方是指，指一方现在或将来控制、受控制或与其处于共同控制下的任何公司、机构以及上述公司或机构的法定代表人。“控制”是指直接或间接地拥有影响所提及公司管理的能力，无论是通过所有权、有投票权的股份、合同或其他被依法认定的方式。
                </li>
                <li>
                  个人信息是指，以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息，包括个人身份信息（姓名、出生日期、出生地、居住地等）、面部特征、地址、联系电话、通信录、网络身份识别信息（包括账号名、账号昵称、邮箱地址以及与前述有关的密码与密码保护问题和答案）；交易信息、个人上网记录（网络浏览记录、点击记录等）、个人常用设备信息（包括硬件型号、设备
                  MAC 地址、操作系统类型、软件列表唯一设备识别码等在内的描述个人常用终端设备基本情况的信息）、个人位置信息等。</li>
                <li>匿名化是指，通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程。</li>
              </ul>
            </label>

            <label class="louyi-text-indent">（三）本“隐私政策”的版权为我们所有，在法律允许的范围内，我们拥有解释和修改的权利。</label>
          </label>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>
