<template>
  <div class="wbj-x20 w1200 spjz louyi-modal" style="margin-top: 85px;margin-bottom: 15px;">
    <Card>
      <h4 style="text-align: center;font-weight: bold;">楼易健康用户服务协议</h4>
      <div class="modal-body">
        <p>
          尊敬的用户，请认真仔细阅读本《用户服务协议》（以下简称“协议”协议）中规定的内容，并确认已完全了解本协议之规定。除非用户接受本协议条款，否则用户无权注册、登录、或使用本协议所涉及的相关服务。用户一旦注册、登录、使用或以任何方式使用本协议所涉及的相关服务的行为将视为对本协议的接受，即表示用户同意接受本协议中的条款的约束。
        </p>
        <p>本协议是用户与楼易健康软件（以下称“本软件”）所有者上海楼易信息技术有限公司之间（以下简称“楼易”），就软件使用服务等相关事宜所订立的协议。</p>
        <p>
          本协议包括正文及所有楼易已经发布的或将来可能发布的各类规则、准则及协议。楼易有权根据需要不时地制定、修改本协议或各类规则。本协议可由楼易随时更新，更新后的协议条款一旦公布即代替原来的协议条款，恕不再另行通知，用户可在本软件中查阅最新版协议条款。在修改协议后，如用户不接受修改后的条款，请立即停止使用本软件提供的服务，若继续使用服务，即被视为用户接受修改后的协议。
        </p>
        <p>无论您停止使用或继续使用本软件，您都承诺不因本协议修改或变更给您造成的损失或不便而向本公司要求赔偿。如果发生纠纷，您不得以未仔细阅读为由进行抗辩。</p>
        <p>
          <label style="font-weight: bold;">一、帐号注册和使用</label>
          <label class="line-left">
            <ul>
              <li>会员在申请本软件网络服务时，应提供准确的个人资料，因会员提供的个人资料不准确、不真实而引发的一切后果由会员承担。</li>
              <li>会员不应将其帐号转让、出借、销售或以任何脱离会员控制的形式交由他人使用。因黑客行为或用户的保管疏忽导致帐号遭他人非法使用，楼易不承担任何责任。</li>
              <li>本软件的帐号所有权归楼易，会员完成注册申请手续后，获得帐号的使用权，会员不得违反本协议约定将帐户用于其他目的。否则楼易有权随时单方限制、中止或终止向会员提供本服务。</li>
              <li>注册帐号绑定的手机号和第三方授权账号是用户登录及使用本服务的凭证，用户应当做好保密措施，因用户保密措施不当或其他行为，致使上述泄露所引起的一切损失和后果，均由会员自行承担。</li>
              <li>
                若发现有他人冒用或盗用帐户、或任何其他未经合法授权的情形时，应立即以有效方式通知本软件，本软件收到会员的有效请求并核实身份后，会根据不同情况采取相应措施，若会员提供的信息不完全，导致无法进行及时处理，带来的损失会员应自行承担。同时，在此之前，由于他人行为给会员造成的损失，本软件不承担任何责任。
              </li>
              <li>本条及本协议其他条款未明示授权的其他一切权利仍由楼易保留，你在行使这些权利时须另外取得楼易的书面许可。楼易如果未行使前述任何权利，并不构成对该权利的放弃。</li>
            </ul>
          </label>
        </p>
        <p>
          <label style="font-weight: bold;">二、关于本服务的中止与终止</label>
        <ul>
          <li>
            本服务的内容：通过您自行设定的生辰信息，提供个性化信息内容服务。收费服务，您可以通过付费方式购买收费服务，本软件会在相关页面上做出提示。如您拒绝支付该等费用，则不能使用相关的服务。对于本软件中的服务内容您应自行加以判断以决定是否接纳建议，并承担因使用内容而引起的所有风险和法律责任。楼易不对用户行为而导致的损失承担责任。
          </li>
          <li>用户具备履行本协议下各种行为的能力，用户履行相关义务和从事相关行为不违反任何对用户的有约束力的法律文件，否则，用户应不适用本软件提供的服务，且应独自承担由此带来的一切责任及给用户、本软件造成的全部损失。</li>
          <li>本软件可能根据实际需要对收费服务的收费标准、方式进行修改、变更，前述修改、变更前，如果会员不同意上述修改、变更，则应停止使用相应服务，否则，会员的任何使用行为，即视为同意上述修改、变更。</li>
          <li>用户在使用本软件某一特定服务时，该服务可能会另有单独的协议、相关业务规则等，用户在使用该项服务前请阅读并同意相关的单独协议；用户使用前述特定服务，即视为你接受相关单独协议。</li>
          <li>楼易健康用户完成会员注册程序，并通过楼易健康平台提供的付费途径完成会员费用的支付后，即取得楼易健康会员资格。</li>
          <li>会员资格有效期届满后，楼易信息将终止向该用户提供会员增值服务，该用户可以通过续交会员服务费用延续会员资格。</li>
          <li>会员在会员资格有效期内可以享受楼易健康会员的各项增值服务。</li>
          <li>楼易健康会员服务是楼易健康为楼易健康用户提供的有偿增值服务，用户向楼易健康支付相关费用后，方可享受专门为会员提供的各项服务。</li>
        </ul>
        </p>
        <p>
          <label style="font-weight: bold;">三、软件的更新</label>
        <ul>
          <li>为了改善用户体验、完善服务内容，楼易将不断努力开发新的服务，并为用户不时提供软件更新（这些更新可能会采取软件替换、修改、功能强化、版本升级等形式）。</li>
          <li>为了保证本软件及服务的安全性和功能的一致性，楼易有权不经向你特别通知而对软件进行更新，或者对软件的部分功能效果进行改变或限制。</li>
          <li>本软件新版本发布后，旧版本的软件可能无法使用。楼易不保证旧版本软件继续可用及相应的服务，用户需随时核对并下载最新版本。</li>
        </ul>
        </p>
        <p>
          <label style="font-weight: bold;">四、隐私保护</label>
        <ul>
          <li>
            楼易重视用户的隐私，楼易保证不对外公开或向第三方提供单个用户的注册资料及用户在使用服务时存储在本软件的非公开内容，但下列情况除外：
            <label class="louyi-text-indent">（1）事先获得用户的明确授权；</label>
            <label class="louyi-text-indent">（2）按照相关政府主管部门的要求；</label>
            <label class="louyi-text-indent">（3）为维护社会公众利益；</label>
            <label class="louyi-text-indent">（4）为维护本软件的合法权益。</label>
            <label class="louyi-text-indent">（5）任何由于黑客攻击、电脑病毒侵入及其他不可抗力事件导致用户个人隐私信息的泄露。</label>
          </li>
          <li>
            用户在注册帐号或使用本服务的过程中，需要提供一些必要的信息，例如：提供内容参考需要填写生辰信息，为向你提供帐号注册服务或进行用户身份识别，需要你填写手机号码；附近的人功能需要你同意使用你所在的地理位置信息；手机通讯录匹配功能需要你授权访问手机通讯录等。若国家法律法规或政策有特殊规定的，你需要提供真实的身份信息。若你提供的信息不完整，则无法使用本服务或在使用过程中受到限制。
          </li>
          <li>本软件可能会与第三方合作向用户提供相关的网络服务，在此情况下，如该第三方同意承担与本软件同等的保护用户隐私的责任，则楼易有权将用户的注册资料等提供给该第三方。</li>
          <li>在不透露单个用户隐私资料的前提下，楼易有权对整个用户数据进行分析和研究，并会以此数据进行app内容优化。</li>
          <li>
            楼易制定了三项隐私权保护原则，指导我们如何来处理产品中涉及到用户隐私权和用户信息等方面的问题：
            <label class="louyi-text-indent">（1）利用收集的信息为用户提供有价值的产品和服务；</label>
            <label class="louyi-text-indent">（2）开发符合隐私权标准和隐私权惯例的产品；</label>
            <label class="louyi-text-indent">（3）尽最大的努力保护掌握的信息；</label>
          </li>
          <li>为了改善本软件的技术和服务，向用户提供更好的服务体验，楼易或可会自行收集使用或向第三方提供用户的非个人隐私信息。</li>
        </ul>
        </p>
        <p>
          <label style="font-weight: bold;">五、用户的声明和保证</label>
        <ul>
          <li>
            用户承诺其为具有完全民事行为能力的民事主体，且具有达成交易履行义务的能力。<br>
            您同意，为避免您的终端数据丢失、更好服务用户的目的，本软件将向注册用户提供数据备份服务，将存储于用户终端的数据库中的数据资料上载至本软件的服务器。若是您自行删除了添加的某个人的生辰信息，相应的备份在数据库中的所有有关的信息也将无法恢复，我们会有明确提示您是否确定删除，对此造成的任何损失，您自行承担。
          </li>
          <li>您同意，仅以非商业目的使用本软件，不对本软件的任何部分或内容进行商业性质利用、复制、拷贝、出售、广告，或将本软件用于其他任何商业目的或商业性质的活动；但楼易与您另行签订有协议使用的商业服务除外。</li>
          <li>您同意，遵守法律及本协议规定，并且保证：不会利用本软件进行非法活动，不会以技术方式攻击或破坏本软件的运行，不会对运行的程序进行反向工程、反向编译或改写。</li>
          <li>您同意：为了向您提供有效的服务，本软件会利用你终端设备的处理器和带宽等资源。本软件使用过程中可能产生数据流量的费用，用户需自行向运营商了解相关资费信息，并自行承担相关费用。</li>
          <li>
            用户在使用本软件及服务时，须自行承担如下来自楼易不可掌控的风险内容，包括但不限于：<br>
            <label
              class="louyi-text-indent">（1）在使用本软件的服务时，须自行承担因行政/司法命令、自然灾害、罢工、暴乱、战争、政府行为等不可抗力因素；因电力供应或通讯网络故障等公共服务等第三方因素；因紧急设备与系统维修、网络信息与数据安全等不可预见及控制的因素所造成的服务中断和个人数据及资料的损失。</label>
            <label class="louyi-text-indent">（2）用户必须选择与所安装终端设备相匹配的软件版本，否则，由于软件与终端设备型号不相匹配所导致的任何问题或损害，均由用户自行承担。品；</label>
            <label class="louyi-text-indent">（3）由于无线网络信号不稳定、无线网络带宽小等原因，所引起的本软件帐号登录失败、资料同步不完整、页面打开速度慢等风险。</label>
          </li>
          <li>
            用户不得从事任何包括但不限于以下的违反法规的行为，也不得为以下违反法规的行为提供便利：<br>
            <label class="louyi-text-indent">（1）违反宪法确定的基本原则的；</label>
            <label class="louyi-text-indent">（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</label>
            <label class="louyi-text-indent">（3）损害国家荣誉和利益的；</label>
            <label class="louyi-text-indent">（4）煽动民族仇恨、民族歧视，破坏民族团结的；</label>
            <label class="louyi-text-indent">（5）破坏国家宗教政策，宣扬邪教的；</label>
            <label class="louyi-text-indent">（6）散布谣言，扰乱社会秩序，破坏社会稳定的；</label>
            <label class="louyi-text-indent">（7）散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</label>
            <label class="louyi-text-indent">（8）侮辱或者诽谤他人，侵害他人合法权益的；</label>
            <label class="louyi-text-indent">（9）发表、传送、传播广告信息及含有任何性暗示的；</label>
            <label class="louyi-text-indent">（10）其他违反法律法规、政策及公序良俗、社会公德或干扰本软件正常运营和侵犯其他用户或第三方合法权益内容的信息。</label>
          </li>
          <li>
            用户不得从事包括但不限于以下行为，也不得为以下行为提供便利：<br>
            <label class="louyi-text-indent">（1）删除、隐匿、改变本软件显示或其中包含的任何专利、著作权、商标或其他所有权声明；</label>
            <label
              class="louyi-text-indent">（2）对本软件提供的基础环境进行修改和删除，或以任何方式干扰或企图干扰本软件的任何产品、任何部分或功能的正常运行，或者制作、发布、传播上述工具、方法等；</label>
            <label class="louyi-text-indent">（3）在未经得本软件书面许可的情况下，以任何方式使用本软件URL地址、技术接口等；</label>
            <label
              class="louyi-text-indent">（4）禁止以文字、语音、图片等任何形式刻意宣传其他竞争平台或个人；也禁止请求、收集、索取或以其它方式获取用户及其它用户的个人信息，包括但不限于QQ号码，微信帐号、邮箱地址等；</label>
            <label class="louyi-text-indent">（5）向用户发布任何商业广告及骚扰信息；</label>
            <label class="louyi-text-indent">（6）其他未经楼易明示授权的行为，楼易认为不应该、不适当的行为、内容。</label>
          </li>
          <li>
            您充分了解并同意，您必须为自己注册帐号下的一切行为负责，包括您所发表的任何内容以及由此产生的任何后果。您应对本服务中的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。楼易无法且不会对因前述风险而导致的任何损失或损害承担责任。
          </li>
          <li>本软件提供的服务中可能包含广告，用户同意在使用过程中显示楼易和第三方供应商、合作伙伴提供的广告。用户因就第三方提供的服务、产品与第三方产生争议的，由用户与第三方自行解决，楼易不承担责任。</li>
          <li>
            用户在我方上传或发布的公开内容，用户应保证其为著作权人或已取得合法授权，并且该内容不会侵犯任何第三方的合法权益。用户同意授予我方所有上述内容在全球范围内的免费、不可撤销的、永久的、可再许可或转让的独家使用权许可，据该许可我方将有权以展示、推广及其他不为法律所禁止的方式使用前述内容。
          </li>
        </ul>
        </p>
        <p>
          <label style="font-weight: bold;">六、知识产权声明</label>
        <ul>
          <li>
            楼易是本楼易健康软件的知识产权权利人。本软件的一切著作权、商标权、专利权、商业秘密等知识产权，以及与本软件相关的所有信息内容（包括但不限于文字、图片、音频、视频、图表、界面设计、版面框架、有关数据或电子文档等）均受中华人民共和国法律法规和相应的国际条约保护，楼易享有上述知识产权，但相关权利人依照法律规定应享有的权利除外。
          </li>
          <li>未经楼易书面同意，用户不得为任何商业或非商业目的自行或许可任何第三方实施、利用、转让上述知识产权。</li>
          <li>未经楼易书面许可，用户不得以任何形式进行创造相关衍生作品。</li>
        </ul>
        </p>
        <p>
          <label style="font-weight: bold;">七、违约</label>
        <ul>
          <li>如果发现或收到他人举报或投诉用户违反本协议约定的，楼易有权不经通知随时对相关内容进行删除，并视行为情节对违规帐号处以包括但不限于警告、限制或禁止使用部分或全部功能。</li>
          <li>用户理解并同意，楼易有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应独自承担由此而产生的一切法律责任。
          </li>
          <li>用户理解并同意，因你违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，用户应当独立承担责任；楼易因此遭受损失的，也应当一并赔偿。</li>
        </ul>
        </p>
        <p>
          <label style="font-weight: bold;">八、免责声明</label>
        <ul>
          <li>用户理解并同意：楼易保留因业务发展需要，单方面对其服务器所提供的服务内容在任何时候不经任何通知的情况下变更、中止、终止部分服务或全部服务的权利。若由此给用户造成的损失的，用户同意放弃追究楼易的责任。</li>
          <li>用户理解并同意：如果一方及其债权人或任何其他合法方申请清算、破产、重组、和解或解除或一方资金无法维持公司正常运作，或一方无法赔偿任何应尽债务，或一方的债权人接管一方的经营，则相对方可终止本协议。</li>
          <li>
            用户理解并同意：为了向用户提供更完善的服务，楼易有权定期或不定期地对提供本服务的平台或相关设备进行检修、维护、升级等，此类情况可能会造成相关服务在合理时间内中断或暂停的，若由此给用户造成损失的，用户同意放弃追究楼易的责任。
          </li>
          <li>
            用户理解并同意：楼易的服务是按照现有技术和条件所能达到的现状提供的。楼易会尽最大努力向用户提供服务，确保服务的连贯性，但楼易不能保证其所提供的服务毫无瑕疵，也无法随时预见和防范法律、技术、黑客攻击，系统不稳定、第三方服务瑕疵、政府行为等原因可能导致的服务中断，数据丢失以及其他的损失和风险。所以用户也同意：即使楼易提供的服务存在瑕疵，但上述瑕疵是当时行业技术水平所无法避免的，其将不被视为楼易违约，同时，由此给用户造成的数据或信息丢失等损失的，用户同意放弃追究楼易的责任。
          </li>
          <li>
            用户理解并同意：在使用本服务果过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，楼易将努力在第一时间与相关单位配合，及时进行修复，由此给用户造成的损失，楼易不承担任何责任。
          </li>
          <li>
            用户理解并同意：若由于以下情况导致服务中断或受阻，给用户造成损失的，楼易不承担任何责任：<br>
            <label class="louyi-text-indent">（1）收到计算机病毒、木马或其他恶意程序、黑客攻击的破坏；</label>
            <label class="louyi-text-indent">（2）用户操作不当；</label>
            <label class="louyi-text-indent">（3）用户通过非本软件授权的方式使用本服务；</label>
            <label class="louyi-text-indent">（4）其他楼易无法控制或合理预见的情形。</label>
          </li>
          <li>用户所有的个人、健康、症状、病情等所有信息均自行提供,需要确保录入或者上传信息及时、准确,应用将完全根据用户提供的信息做出反馈,如果用户提供的信息有误,造成的任何后果自行承担。</li>
          <li>
            如用户的症状及疾病或者存在任何健康信息在应用系统中无法体现时,默认该用户不符合本软件服务要求,软件拒绝提供服务,所有推荐信息均不得作为任何意义上的参考。如擅自获得信息并作为参考,任何后果用户自行承担。</li>
          <li>本软件推荐不能代替到医院就诊,尤其是用户有重度疾病或者发生急性症状或者化验结果严重异常的情况下,都需要尽快就医,本软件推荐不做任何参照。</li>
          <li>
            用户提供的健康信息、提交的健康日记、及相关的一切记录,如果本软件认为其内容包含任何不基于事实、虚构、编造及无亲身经历的言论,带有威胁、淫秽、漫骂、非法及带有人身攻击之言论,含有色情、暴力、恐怖内容、含有违背伦理道德内容,涉及违法犯罪的内容,以及其他楼易认为不恰当的情况,楼易有权进行编辑、删除、直至取消该账户资格,并保留进行法律追究的权利。
          </li>
          <li>
            本软件的健康信息，仅供使用者参考之用，并不构成医疗忠告，也不能取代医生提供的医疗和护理服务。在任何情况下，请事前咨询您的医生，以获得适当的检查、治疗和护理建议，请勿倚赖本网站的信息作为自我诊断、保健或养生的依据。对于因不当使用、不当理解或应用本网站提供的任何内容或信息而引起的任何不良后果，本公司概不承担任何责任。
          </li>
          <li>除非另有明确的书面说明, 楼易不对本软件的运营及其包含在本软件上的信息、内容、材料、产品（包括软件）或服务作任何形式的、明示或默示的声明或担保（根据中华人民共和国法律另有规定的以外）。</li>
        </ul>
        </p>
        <p>
          <label style="font-weight: bold;">九、订单</label>
        <ul>
          <li>
            在您下订单时，请您仔细确认所购商品的名称、价格、数量、型号、规格、尺寸、联系地址、电话、收货人等信息。收货人与您本人不一致的，收货人的行为和意思表示视为您的行为和意思表示，您应对收货人的行为及意思表示的法律后果承担连带责任。
          </li>
          <li>
            您理解并同意：楼易健康上销售商展示的商品和价格等信息仅仅是要约邀请，您下单时须填写您希望购买的商品数量、价款及支付方式、收货人、联系方式、收货地址（合同履行地点）、合同履行方式等内容；系统生成的订单信息是计算机信息系统根据您填写的内容自动生成的数据，仅是您向楼易健康发出的合同要约；楼易健康收到您的订单信息后，只有在楼易健康将您在订单中订购的商品从仓库实际直接向您发出时（以商品出库为标志），方视为您与楼易健康之间就实际直接向您发出的商品建立了合同关系；如果您在一份订单里订购了多种商品并且楼易健康只给您发出了部分商品时，您与楼易健康之间仅就实际直接向您发出的商品建立了合同关系，只有在楼易健康实际直接向您发出了订单中订购的其他商品时，您和楼易健康之间就订单中其他已实际直接向您发出的商品才成立合同关系；对于数字化商品，您下单并支付货款后合同即成立。
          </li>
          <li>
            尽管楼易健康做出最大的努力，但由于市场变化及各种以合理商业努力难以控制因素的影响，楼易健康无法避免您提交的订单信息中的商品出现缺货、价格标示错误等情况；如您下单所购买的商品出现以上情况，您有权取消订单，楼易健康亦有权自行取消订单，若您已经付款，则为您办理退款。
          </li>
        </ul>
        </p>
        <p>
          <label style="font-weight: bold;">十、配送和交付</label>
        <ul>
          <li>
            您在楼易健康购买的商品将按照本APP上您所指定的送货地址进行配送。订单信息中列出的送货时间为参考时间，参考时间的计算是根据库存状况、正常的处理过程和送货时间、送货地点的基础上估计得出的。您应当清楚准确地填写您的送货地址、联系人及联系方式等配送信息，您知悉并确认，您所购买的商品应仅由您填写的联系人接受身份查验后接收商品，因您变更联系人或相关配送信息而造成的损失由您自行承担。
          </li>
          <li>
            因如下情况造成订单延迟或无法配送等，楼易健康将无法承担迟延配送或无法配送的责任：<br>
            <label class="louyi-text-indent">（1）客户提供错误信息和不详细的地址；</label>
            <label class="louyi-text-indent">（2）货物送达无人签收或被拒收，由此造成的重复配送所产生的费用及相关的后果。</label>
            <label
              class="louyi-text-indent">（3）不可抗力，例如：自然灾害及恶劣天气、交通戒严等政府、司法机关的行为、决定或命令、意外交通事故、罢工、法规政策的修改、恐怖事件、抢劫、抢夺等暴力犯罪、突发战争等。</label>
          </li>
          <li>
            您在楼易健康购买的商品由楼易的关联方或第三方配送公司为您完成订单交付的，系统或单据记录的签收时间为交付时间；您购买的商品采用在线传输方式交付的，楼易健康向您指定系统发送的时间为交付时间；您购买服务的，生成的电子或者实物凭证中载明的时间为交付时间。
          </li>
        </ul>
        </p>
        <p>
          <label style="font-weight: bold;">十一、服务费用</label>
        <ul>
          <li>会员资格以用户通过购买会员资格期限的方式提供会员服务，会员服务以年为单位。楼易信息将根据不同的购买方式和数量，向用户提供不同幅度的优惠，具体优惠信息以会员资格支付页上内容为准。</li>
          <li>
            楼易信息可以根据实际情况随时调整会员服务费用标准，经调整的会员服务费用标准将在会员资格支付页上显示，用户按调整后的服务费用标准支付后方可享有或延续会员资格。已按原会员服务费用标准购买会员服务的用户，其会员资格以及所享有的会员服务在已取得的会员资格期限内不受影响，无需补充缴纳差额。
          </li>
          <li>用户在向楼易支付费用后，用户可使用楼易相应的收费服务。用户在支付费用前，应当慎重考虑，一旦支付费用后，用户不得再以任何理由要求楼易退款（楼易明确表示关停全部的公司和本app服务，且不再提供本app服务的除外）。
          </li>
          <li>如会员在会员资格有效期内主动终止或取消会员资格，楼易信息不负责退还与该会员剩余会员资格有效期对应的服务费用。</li>
        </ul>
        </p>
        <p>
          <label style="font-weight: bold;">十二、其他条款</label>
        <ul>
          <li>您已了解国际互联网的无国界性，同意遵守当地所有关于网上行为及内容之法律法规。您特别同意遵守有关从中国或您所在国家或地区输出信息之传输的所有适用法律法规。</li>
          <li>本协议的成立、生效、履行、解释及纠纷解决、适用中华人民共和国法律、法规。</li>
          <li>用户与楼易之间发生的任何纠纷或争议，首先应友好协商解决，协商不成功的，任何一方均有权将争议递交至楼易所在地有管辖权的人民法院依据中国法律解决。</li>
          <li>本协议最终解释权与修改权归楼易所有。</li>
        </ul>
        </p>
      </div>
      <div class="text-center">
        <Button @click="toAppDownload">返回</Button>
      </div>
    </Card>
  </div>
</template>

<script>
export default {
  methods: {
    toAppDownload() {
      this.$router.push({
        name: "AppDownload",
      });
    },
  },
};
</script>
