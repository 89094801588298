<template>
  <div>
    <!-- 轮播图 -->
    <div class="slider5 tp100 cd-main-content">
      <li>
        <img src="/public/webpic/20190916/a1c290e20163c37532d13ab8aa44dd48.jpg" />
      </li>
    </div>

    <div class="w1200 spjz nbj-sx40">
      <div class="spbj lddq">
        <div class="autoMenu wow a-fadeinB" id="autoMenu" data-autoMenu></div>

        <div class="w-900 fr wow a-fadeinB" data-wow-delay="0.2s" id="w-900">
          <!-- 视频 -->
          <div class="ys-f2">
            <h5>视频</h5>
          </div>
          <div class="w100 spjz z-100 wow a-fadeinB">
            <video src="/public/shipin/20190719/38adaca13febbc174bf4fe34edd34749.mp4"
              poster="/public/pagepic/20190719/81ed85191c06572db62aa30249cb430f.jpg" width="100%" height="auto"
              style="background: #000; margin-top: -30px" controls></video>
          </div>

          <!-- 楼易介绍 -->
          <div class="ys-f2">
            <h5>楼易介绍</h5>
          </div>
          <div class="bjys-fff nbj-25 wow a-fadeinB">
            <div class="wzjz ys-be nbj-sx10">
              <div class="qzhh zt26 ztjc nbj-sx5">INTRODUCTION</div>
              <div class="ys-4d zt24 ztjc">楼易介绍</div>
            </div>
            <div class="fgx-3 bjys-0a w-30 spjz"></div>
            <div class="nbj-sx20">
              <p>
                楼易信息技术(上海)有限公司，旗下拥有品牌楼易健康(Louyi
                AI)，由楼中亮博士创办于2018
                年3月，是一家以中医理论体系为核心，辅助以AI人工智能和大数据等技术服务，为用户提供健康诊断报告及健康调理解决方案的创新型AI健康科技企业。
              </p>
              <p><br /></p>
              <p>
                依托中医理论背景及应用AI人工智能和大数据，楼易健康发力AI健康检测、大数据健康管理、健康调理解决方案、健康产品整合供应链等业务板块，建立其“AI大数据+健康管理”的科技健康产业布局。
              </p>
            </div>
          </div>

          <!-- 楼易理念 -->

          <div class="ys-f2">
            <h5>楼易理念</h5>
          </div>
          <div class="spbj lddq nbj-sx20 wow a-fadeinB" data-wow-delay="0.3s">
            <div class="w48 tp100 nbj-s10">
              <img src="/public/static/index/img/new-gy2.jpg" />
            </div>
            <div class="w50">
              <div class="ys-be nbj-x10">
                <div class="qzhh zt26 ztjc nbj-sx5">CONCEPT</div>
                <div class="ys-4d zt24 ztjc">楼易理念</div>
              </div>
              <div class="fgx-3 bjys-0a w-30"></div>
              <div class="nbj-sx10">
                <div class="nbj-sx5 lh-20">
                  <p><br /></p>
                  <p>
                    藉由数字化，让大众透过科技来认识及运用中医知识，并系统化的帮助使用者了解自己的身体状况。
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- 创始人 -->
          <div class="fgx-10"></div>

          <div class="ys-f2">
            <h5>创始人介绍</h5>
          </div>
          <div class="qzhh wow a-fadeinB">
            <div class="csrjs" style="background: #fff">
              <div class="csrbg fr"></div>
            </div>
          </div>

          <div id="Founder" class="w1200 spjz wow a-fadeinB" data-wow-delay="0.3s">
            <div class="spbj wbj-s-480 lddq">
              <div class="w50 z-100">
                <div class="nbj-s160 nbj-25">
                  <div class="ys-be nbj-sx10">
                    <div class="qzhh zt26 ztjc nbj-sx5">FOUNDER</div>
                    <div class="ys-4d zt24 ztjc">创始人介绍</div>
                  </div>
                  <div class="fgx-3 bjys-0a w-30"></div>
                  <div class="nbj-sx10">
                    <div class="nbj-sx5 ys-99 lh-20">
                      楼中亮——台湾著名中医师，曾获台湾中国医药大学学士学位、南京中医药大学中医养生康复学科博士学位
                    </div>
                    <div class="nbj-sx5 zt12 ys-aa lh-20">
                      <p>
                        现任台湾中医预防保健协会创会理事长、台湾周易文化研究会理事,自1998年起正式执业,最初是台湾中部地区的减重名医，全台各地都有患者闻名而至,短短几年内就累积了极高的知名度。2010年,出版个人第一-本著作《算病》,2018年初,于.上海成立了「楼易信息技术(上海)有限公司」，历经二十多年的潜心研究和实践,将其独创的中医预防保健系统结合古中医疗法整合成一套?
                        治未病」的诊断系统一楼易AI中医诊疗机器人APP。
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="w45 z-100 tp100">
                <img src="/public/pagepic/20190826/80f9087130cdd138ef997040f578942b.png" />
              </div>
            </div>
          </div>

          <!-- 发展历程 -->
          <div class="nbj-sx20 wow a-fadeinB">
            <div class="ys-f2">
              <h5>发展历程</h5>
            </div>
            <div class="wzjz ys-be nbj-sx10">
              <div class="qzhh zt26 ztjc nbj-sx5">DEVELOPMENT COURSE</div>
              <div class="ys-4d zt24 ztjc">发展历程</div>
            </div>
            <div class="fgx-3 bjys-0a w-30 spjz"></div>
            <div class="nbj-25 ys-aa">
              <p style="text-align: left"><b>= 核心业务 =</b></p>
              <p>
                &nbsp; &nbsp;AI 健 康 检
                测：楼易健康借助AI人工智能+大数据技术，为用户提供身体健康检测服务。
              </p>
              <p>
                &nbsp;
                &nbsp;健康调理方案：科学分析用户各类健康状况信息，结合中医保健，为用户提供一套健康调养方案。
              </p>
              <p>
                &nbsp;
                &nbsp;健康社区精选：提供楼易健康出品的预防保健知识、健康管理等相关文章、视频内容，用户可以随时观看和学习。
              </p>
              <p><br /></p>
              <p style="text-align: left"><b>= 软件产品 =</b></p>
              <p>
                &nbsp;
                &nbsp;楼易健康管理APP是楼易推出的AI中医诊疗APP，为用户提供一站式的互联网+AI健康管理服务。
              </p>
              <p><br /></p>
              <p style="text-align: left"><b>= 企业大事记 =</b></p>
              <p>
                &nbsp; &nbsp;1998年_创始人楼中亮博士首家诊所在台湾正式开业。
              </p>
              <p>
                &nbsp;
                &nbsp;2010年_将中医预防保健系统整合中医疗法，使其数字化、生活化、普及化，提供客户切合个人所需的产品。
              </p>
              <p>
                &nbsp; &nbsp;2018年_「楼易信息技术(上海)有限公司」正式成立。
              </p>
              <p>&nbsp; &nbsp;2019年_楼易AI健康管理APP启动。</p>
            </div>
            <div class="tp100">
              <img src="/public/static/index/img/gy3.jpg" />
            </div>
          </div>

          <!-- 著作介绍 -->
          <div class="ys-f2">
            <h5>著作介绍</h5>
          </div>
          <div class="nbj-sx10 wow a-fadeinB">
            <div class="wzjz ys-be nbj-sx10">
              <div class="qzhh zt26 ztjc nbj-sx5">ABOUT AUTHOR</div>
              <div class="ys-4d zt24 ztjc">著作介绍</div>
            </div>
            <div class="fgx-3 bjys-0a w-30 spjz"></div>

            <div class="w1200 spjz nbj-sx20">
              <div class="spbj bjhh">
                <div v-for="book in books" :key="book.id" class="w25 h-470 czbj nbj-sx20">
                  <a class="xsyd" @click="clickArticle(book)">
                    <div class="tp-h300 tp100">
                      <img :src="book.preview_img" />
                    </div>
                    <div class="bjys-fff nbj-5-10 nbj-x10">
                      <div class="zt16 ztjc wbj-s10 lh-30">
                        {{ getLang(book,'title') }}
                      </div>
                      <div class="ys-666 wbj-s10 h-30 lh-30 qzhh">
                        {{ getLang(book,'description') }}
                      </div>
                      <div class="spbj lddq nbj-s10">
                        <!-- <li class="ys-666">0元</li> -->
                        <!-- <li class="ys-0a ztjc">more>></li> -->
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AppQRCodes />
  </div>
</template>
<script>
import autoMenu from "/public/static/index/js/jquery.autoMenu.js";
export default {
  props: {
    moveToID: {
      type: String,
      default: "",
    },
  },
  mounted() {
    autoMenu();
    $(".slider5").bxSlider({
      mode: "fade",
      controls: false,
      auto: false,
      autoControls: false,
      slideMargin: 10,
      pager: false,
    });
    $(window).scroll(function () {
      var scrolls = $(window).scrollTop();
      if (scrolls > 560) {
        $("#autoMenu").addClass("gylygd");
        $("#w-900").addClass("wbj-z300");
      } else {
        $("#autoMenu").removeClass("gylygd");
        $("#w-900").removeClass("wbj-z300");
      }
    });
    if (this.moveToID) {
      setTimeout(() => {
        scrollToID(this.moveToID);
      }, 1000);
    }
  },
  computed: {
    articles() {
      return this.$store.state.articles;
    },
    books() {
      return this.articles.filter((article) => article.category == "book");
    },
  },
  methods: {
    //取得語系內容
    getLang(row, type) {
      return getArticleLang(row, type);
    },
    clickItem(id) {
      scrollToID(id);
    },
    clickArticle(article) {
      this.$router.push({
        name: "ArticleDetail",
        params: { id: article.id, article },
      });
    },
  },
  watch: {
    $route() {
      autoMenu();
    },
  },
};
</script>
