<template>
  <div id="wrap">
    <div id="msgbox" style="display: none" param=""></div>

    <div id="gotop" style="display: none; z-index: 10">
      <img
        src="/img/4663c010-d468-4323-b215-1f661e8897b2.png"
        width="50px"
        alt=""
      />
    </div>
    <Header id="header" />
    <router-view id="main-wrap" class="main-container"></router-view>
    <Footer v-if="showFooter" id="footer" />
    <Spin size="large" fix v-if="showLoading" style="position: fixed"></Spin>
    <Modal v-model="dialog.show" :closable="false" :mask-closable="false">
      <pre style="text-align: left" v-html="dialog.content"></pre>
      <div slot="footer">
        <Button v-show="dialog.type == 'confirm'" @click="dialogNo">{{
          dialog.noText
        }}</Button>
        <Button @click="dialogYes">{{ dialog.yesText }}</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
import ArticleApiHelper from "~/resources/js/script/Utility/ArticleApiHelper";
import Header from "~/resources/js/components/component/Header.vue";
import Footer from "~/resources/js/components/component/Footer.vue";
export default {
  components: { Header, Footer },

  beforeCreate() {
    const push = this.$router.push.bind(this.$router);
    this.$router.push = (data) => {
      // onMenuButtonClick(false);
      if (!compare(data, this.$router.currentRoute)) {
        push(data);
      }
    };
    window.alert = (content, yesCallback) => {
      this.setDailog(content, "alert", yesCallback, null);
    };
    window.confirm = (content, yesCallback, noCallback) => {
      this.setDailog(content, "confirm", yesCallback, noCallback);
    };
    window.loading = (isShow = true) => {
      this.showLoading = isShow;
    };
    window.errorHandler = (...args) => {
      this.errorHandler(...args);
    };
    window.scrollToTop = (...arg) => {
      this.scrollToTop(...arg);
    };
    window.scrollToID = (...arg) => {
      this.scrollToID(...arg);
    };
    if (!this.$store.state.articles.length) {
      loading();
      ArticleApiHelper.getArticle()
        .then((result) => {
          this.$store.commit("articles", result.data);
          loading(false);
        })
        .catch(errorHandler);
    }
  },
  mounted() {
    this.setPage();
    const loadingItems = {};
    const loadingFinish = (target) => {
      delete loadingItems[target];
      if (!Object.keys(loadingItems).length) {
        loading(false);
      }
    };
    if (!this.$store.state.articles.length) {
      loadingItems["article"] = true;
      loading();
      ArticleApiHelper.getArticle()
        .then((result) => {
          result.data.forEach((article) => {
            article.timestamp = article.timestamp.split(" ")[0];
          });
          this.$store.commit("articles", result.data);
          loadingFinish("article");
        })
        .catch((e) => {
          errorHandler(e);
          loading();
          loadingFinish("article");
        });
    }
    if (!this.$store.state.pages.length) {
      loadingItems["page"] = true;
      loading();
      ArticleApiHelper.getPage()
        .then((result) => {
          this.$store.commit("pages", result.data);
          loadingFinish("page");
        })
        .catch((e) => {
          errorHandler(e);
          loading();
          loadingFinish("page");
        });
    }
  },
  data() {
    return {
      isBlank: this.$router.currentRoute.meta.group == "blank",
      showLoading: false,
      defaultDialog: {
        show: false,
        content: "",
        yesText: "確定",
        noText: "取消",
        yesCallback: null,
        noCallback: null,
      },
      dialog: {
        type: "",
        show: false,
        content: "",
        yesText: "",
        noText: "",
        yesCallback: null,
        noCallback: null,
      },
    };
  },
  methods: {
    scrollToID(id) {
      if (id) {
        let eleId = $("#" + id)[0];
        let par = eleId.offsetParent;
        let top = eleId.offsetTop;
        // if (par.nodeName.toLowerCase() == "body") {
        //   // 当节点为body可直接使用offsetTop获取距离
        //   top += eleId.offsetTop; // 获取高度
        // } else {
        //   while (par) {
        //     // 循环获取当前对象与body的高度
        //     top += par.offsetTop;
        //     par = par.offsetParent;
        //   }
        // }
        this.scrollTo(top - 50);
      }
    },
    scrollToTop() {
      this.scrollTo(0);
    },
    scrollTo(top) {
      window.scrollTo({
        top: top,
        left: 0,
        behavior: "smooth",
      });
    },
    setDailog(content, type, yesCallback, noCallback) {
      Object.assign(this.dialog, this.defaultDialog);
      this.dialog.type = type;
      this.dialog.show = true;
      if (typeof content == "string") {
        this.dialog.content = content;
        this.dialog.yesCallback = yesCallback;
        this.dialog.noCallback = noCallback;
      } else if (typeof content == "object") {
        Object.assign(this.dialog, content);
      }
    },
    dialogYes() {
      this.dialog.show = false;
      if (typeof this.dialog.yesCallback == "function") {
        setTimeout(this.dialog.yesCallback, 100);
      }
    },
    dialogNo() {
      this.dialog.show = false;
      if (typeof this.dialog.noCallback == "function") {
        setTimeout(this.dialog.noCallback, 100);
      }
    },
    errorHandler(e) {
      if (e.errorCode == 101999) {
        this.$router.push({ name: "Home" });
      } else if (e.errorCode == 100999) {
        this.$router.push({ name: "Verify" });
      } else if (e.errorCode == 100998) {
        this.$router.push({ name: "Result" });
      }
      loading(false);
      alert(e.message);
    },
    setPage() {
      if (this.$route.name == "Home") {
        $("body").attr("id", "bodyinhome");
      } else {
        $("body").attr("id", "bodyinpage");
      }
    },
  },
  computed: {
    showFooter() {
      return !this.$route.meta.noFooter;
    },
  },
  updated() {
    // AOS.init();
  },
  watch: {
    $route(to, from) {
      this.setPage();
      this.scrollToTop();
    },
  },
};
</script>
<style>
@media only screen and (max-width: 768px) {
  body {
    font-family: "Montserrat", "Open Sans", -apple-system, "Helvetica Neue",
      Helvetica, Arial, "Lantinghei TC", "Microsoft JhengHei", sans-serif;
  }

  div.navbar.navbar-fixed-top {
    justify-content: flex-start;
    padding: 0;
    height: fit-content;
    min-height: unset;
  }

  div.navbar.navbar-fixed-top > div.container {
    display: none;
    padding: 0;
    justify-content: flex-start;
    align-items: center;
    background-color: transparent;
  }

  button#newMenuButton {
    display: inline-flex;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    outline: none;
    transition: all 500ms;
    border: none;
    background-color: transparent;
    color: white;
    cursor: pointer;
    width: fit-content;
    z-index: 1;
    font-weight: 500;
    min-height: 40px;
  }

  button#newMenuButton > * {
    color: #000;
    font-weight: 500;
    font-size: 14px;
  }

  button#newMenuButton > i {
    font-size: 28px;
  }

  button#newMenuButton:hover,
  button#newMenuButton.expand {
    transform: translate(-2px, 0px);
  }

  button#newMenuButton.closed {
    transform: translate(-0px, -0px);
  }
}

@media only screen and (min-width: 769px) {
  button#newMenuButton {
    display: none;
  }
}
</style>