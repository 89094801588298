<template>
  <div id="main-wrap" class="main-container productdetail mt-60">
    <div class="fcol fcol-1 bjys-fff richScope">
      <div class="container">
        <div class="main side-height">
          <div class="detail-img popup-gallery lightbox" id="magnifier" data-enable="0">
            <div class="main-image-area position-relative">
              <a :title="getLang(article, 'title')" :href="article.img">
                <img :alt="getLang(article, 'title')" :src="article.img" class="photoBig" id="2334736"
                  :data-zoom-image="article.img" />
              </a>
              <span class="zoominfo"><i class="fa fa-search-plus"></i> 點擊圖片放大</span>
            </div>
          </div>
          <div class="detail-spec" style="margin-top: 10%">
            <h2>
              <div id="productName" class="product-content edit-area">
                <p v-html="getLang(article,'title')"></p>
              </div>
            </h2>
            <div id="productSpec" class="product-content edit-area">
              <p>
                {{ getLang(article,'description') }}
              </p>
            </div>
          </div>
          <div class="detail-desc">
            <b>詳細介紹:</b>
          </div>
          <div id="productDesc" class="product-content edit-area" v-html="getLang(article,'content')"></div>
        </div>
      </div>
    </div>
    <!-- 精选文章 -->
    <div class="fgx-30"></div>
    <div class="wbj-s30 wow a-fadeinB" style="visibility: visible; animation-name: fadeinB">
      <div class="w1200 spjz wzjz">
        <div class="ys-fff zt46 ztjc qzdx">related suggestion</div>
        <div class="ztjc wbj-s-25 zt22">相关推荐</div>
        <div class="fgx-30"></div>
        <div class="fgx-1 spjz w-400 bjys-99"></div>
        <div class="wbj-s-15 qzdx">
          <li class="w-160 spjz ys-666 zt22 bjys-f2">INTRODUCE</li>
        </div>
      </div>
    </div>

    <div class="wbj-s10 wow a-fadeinB" style="visibility: visible; animation-name: fadeinB">
      <div class="w1200 spjz">
        <div class="spbj bjhh">
          <div v-for="article in moreArticles" :key="article.id" class="w25 h-450 czbj nbj-sx20">
            <a @click="clickItem(article)" class="xsyd xfbk">
              <div class="tp-h300">
                <img :src="article.preview_img" />
              </div>
              <div class="bjys-fff nbj-5-10 nbj-x10">
                <div class="zt16 ztjc lh-30">
                  {{ getLang(article,'title') }}
                </div>
                <div class="fgx-3 w-20 bjys-0a"></div>
                <div class="ys-666 h-30 lh-30 qzhh">
                  {{ getLang(article,'description') }}
                </div>
                <div class="spbj lddq nbj-s10">
                  <li class="ys-666">{{ article.timestamp }}</li>
                  <li class="ys-0a ztjc">more&gt;&gt;</li>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="fgx-30"></div>

    <div class="fgx-60 bjys-fff"></div>
  </div>
</template>
<script>
export default {
  props: {
    id: {
      type: String | Number,
      default: "",
    },
    article: {
      type: Object,
      default() {
        return {
          id: "",
          title: "",
          description: "",
          content: "",
          img: "",
          preview_img: "",
          category: "",
          other: "",
          source: "",
          tag: "",
          timestamp: "",
          type: "",
          hot: false,
          active: true,
        };
      },
    },
  },
  mounted() {},
  data() {
    return {};
  },
  computed: {
    articles() {
      this.$store.state.articles.find((article) => {
        if (article.id == this.id) {
          Object.assign(this.article, article);
          return true;
        }
        return false;
      });

      return this.$store.state.articles;
    },
    moreArticles() {
      const moreArticles = this.articles.filter(
        (article) =>
          this.isMatchCategory(article.category, this.article.category) &&
          article.tag == this.article.tag &&
          article.type == this.article.type
      );
      let maxCount = 8;
      maxCount =
        moreArticles.length < maxCount ? moreArticles.length : maxCount;
      let more = [];
      const index = moreArticles.findIndex((article) => article.id == this.id);
      more = moreArticles.slice(index + 1, index + 1 + maxCount);
      const realCount = more.length;
      if (realCount < maxCount) {
        more.push(...moreArticles.slice(0, maxCount - realCount));
      }
      return more;
    },
  },
  methods: {
    //取得語系內容
    getLang(row, type) {
      return getArticleLang(row, type);
    },
    isMatchCategory(category1, category2) {
      const featuredCategories = ["featured", "healthy", "beauty"];
      if (featuredCategories.indexOf(category1) >= 0) {
        return featuredCategories.indexOf(category2) >= 0;
      }
      return category1 == category2;
    },
    clickItem(article) {
      var pathList = location.pathname.split("/");
      const nowId = parseInt(pathList[pathList.length - 1].split(".")[0]);
      if (nowId != article.id) {
        this.$router.push({
          name: "ArticleDetail",
          params: { id: article.id, article },
        });
      }
    },
  },
};
</script>
<style lang="scss"  scoped>
body .zoominfo {
  line-height: 1.5;
}
.productdetail .detail-spec h2 {
  color: #666;
  font-size: 30px;
  line-height: 1.3;
  margin: 4px 0 8px 0;
  text-shadow: 0 1px 0 #fff;
  font-family: inherit;
  p {
    font-size: 30px;
    line-height: 1.3;
    font-family: inherit;
  }
}
</style>
<style>
#productName .zt16 {
  font-size: 30px;
  color: #666666;
}
</style>
