<template>
  <div>
    <!-- 轮播图 -->
    <div class="slider5 tp100 cd-main-content">
      <li>
        <img
          src="/public/webpic/20190916/373b7979b18641dafee9dd984223d81b.jpg"
        />
      </li>
    </div>
    <!-- 人的健康 -->
    <div class="wbj-s40">
      <div class="nbj-sx40">
        <div class="w1200 spjz wow a-fadeinB">
          <div class="wzjz ys-666 zt18 ztjc">
            人的健康=60%的先天体质+40%的后天体质
          </div>
        </div>
        <div class="w1200 spjz wow a-fadeinB" data-wow-delay="0.5s">
          <div class="bjys-00">
            <div class="spbj jzdq">
              <div class="tab zt16 ys-666 checked">先天体质</div>
              <div class="tab zt16 ys-666">后天体质</div>
            </div>
          </div>
          <div>
            <div class="tablelist spbj">
              <div class="w50 h-522 tp100">
                <img
                  src="/public/static/index/img/ai2.jpg"
                  style="height: 100%"
                />
              </div>
              <div class="w50 h-522 czbj wzjz nbj-sx40 bjys-fff">
                <div class="nbj-sx20 zt16">出生时天地气候</div>
                <div class="bjys-0a ys-fff wh-50 bkyx spjz">影响</div>
                <div class="ys-0a zt22 zjfz wh-55 spjz">》</div>
                <div class="nbj-sx20 zt16">先天体质的强弱与偏性</div>
                <div class="bjys-0a ys-fff wh-50 bkyx spjz">决定</div>
                <div class="ys-0a zt22 zjfz wh-55 spjz">》</div>
                <div class="nbj-sx20 w-360 spjz zt16">
                  先天体质决定一个人易患哪些疾病，因此，必须找出先天体质，才能透过后天的调理来避免疾病发生
                </div>
              </div>
            </div>
            <div class="tablelist qzhh spbj hide">
              <div class="w50 h-522 fl czbj wzjz nbj-sx40 bjys-fff">
                <div class="nbj-sx20 zt16">饮食作息、生活环境、个性等</div>
                <div class="bjys-0a ys-fff wh-50 bkyx spjz">影响</div>
                <div class="ys-0a zt22 zjfz wh-55 spjz">》</div>
                <div class="nbj-sx20 zt16">后天体质的改变</div>
                <div class="bjys-0a ys-fff wh-50 bkyx spjz">决定</div>
                <div class="ys-0a zt22 zjfz wh-55 spjz">》</div>
                <div class="nbj-sx20 w-360 spjz zt16">
                  根据后天体质，能够推算出近期内的好发疾病
                </div>
              </div>
              <div class="w50 h-522 fl tp100" style="overflow: hidden">
                <img
                  src="/public/static/index/img/ai3-1.png"
                  style="height: 100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bjys-fff"></div>
    <!-- 下拉图标 -->
    <div class="wbj-s-15">
      <div class="nbj-sx30 w-30 tp100 spjz wow a-fadeinB">
        <img src="/public/static/index/img/ai3-11.png" />
      </div>
      <div class="wzjz wow a-fadeinB">
        <li>综合两种体质数据，能够预知每个人一生中容易产生哪些疾病?</li>
        <li>应该如何调理?并给出一套针对个人的健康调理解决方案。</li>
      </div>
    </div>

    <!-- AI检测 -->
    <div class="qzhh wow a-fadeinB" data-wow-delay="0.1s">
      <div class="wbj-s48">
        <div
          class="tlxq"
          style="
            background: #5e5b58 url(/public/static/index/img/csr.jpg) no-repeat
              center fixed;
            background-size: 100% auto;
          "
        >
          <div class="w1200 spjz">
            <div class="wbj-20 tmbj"></div>
            <div class="w1200 bkys-fff qzhh h-340 jdwz-s45">
              <div class="ys-fff">
                <div class="w1200 spjz nbj-zy30 ys-fff">
                  <div class="bjys-00">
                    <div class="spbj jzdq">
                      <div class="tab2 checked">找出先天体质偏性</div>
                      <div class="tab2">测出后天体质</div>
                    </div>
                  </div>
                  <div>
                    <div class="table2list spbj">
                      <div class="w100 czbj wzjz nbj-sx10">
                        <div class="zt18">检测原理</div>
                        <div class="ys-0a zt22 zjfz spjz">》</div>
                        <div class="wzjz nbj-zy120 lh-20">
                          楼易运用《黄帝内经》中的【五运六气天气医学】，用户提供出生生辰、血型等，再结合《易经》，
                          透过大数据分析获取最为精准的先天体质，从而推算出容易罹患的疾病。
                        </div>
                        <div class="lh-20 nbj-s10">
                          <div class="zt18">AI检测步骤</div>
                          <div class="zt12">
                            填写基础资料（资料的准确性将影响检测结果）
                          </div>
                        </div>
                        <div class="ys-0a zt22 zjfz spjz">》</div>
                        <div class="spbj jzdq">
                          <div class="jzdq wbj-zy30">
                            <div class="bjys-a0 wh-50 nbj-5 spjz bkyx tp100">
                              <img src="/public/static/index/img/ai4.png" />
                            </div>
                            <div class="lh-30">性别</div>
                          </div>
                          <div class="jzdq wbj-zy30">
                            <div class="bjys-fff wh-50 nbj-5 spjz bkyx tp100">
                              <img src="/public/static/index/img/ai5.png" />
                            </div>
                            <div class="jzdq">
                              <div class="lh-30">出生年月日</div>
                              <div class="zt12 wbj-s-10 lh-20">
                                （需区别阴历和阳历）
                              </div>
                            </div>
                          </div>
                          <div class="jzdq wbj-zy30">
                            <div class="bjys-fff wh-50 nbj-5 spjz bkyx tp100">
                              <img src="/public/static/index/img/ai6.png" />
                            </div>
                            <div class="lh-30">血型</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="table2list hide spbj">
                      <div class="w100 czbj wzjz nbj-sx10">
                        <div class="zt18">检测原理</div>
                        <div class="ys-0a zt22 zjfz spjz">》</div>
                        <div class="wzjz nbj-zy120 lh-20">
                          楼易运用中医的【掌纹医学】，根据掌纹、面诊、舌诊观察未病、辨证已病，用户进行掌纹检测，再结合后天因素，进行后天体质检测，推算出近期内的好发疾病及调理方案。
                        </div>
                        <div class="lh-20 nbj-s10">
                          <div class="zt18">AI检测步骤</div>
                          <div class="zt12">
                            填写基础资料（资料的准确性将影响检测结果）
                          </div>
                        </div>
                        <div class="ys-0a zt22 zjfz spjz">》</div>
                        <div class="spbj jzdq">
                          <div class="jzdq wbj-zy30">
                            <div class="bjys-fff wh-50 nbj-5 spjz bkyx tp100">
                              <img src="/public/static/index/img/ai7.png" />
                            </div>
                            <div class="lh-30">基本资料</div>
                          </div>
                          <div class="jzdq wbj-zy30">
                            <div class="bjys-a0 wh-50 nbj-5 spjz bkyx tp100">
                              <img src="/public/static/index/img/ai8.png" />
                            </div>
                            <div class="lh-30">掌纹检测</div>
                          </div>
                          <div class="jzdq wbj-zy30">
                            <div class="bjys-fff wh-50 nbj-5 spjz bkyx tp100">
                              <img src="/public/static/index/img/ai9.png" />
                            </div>
                            <div class="lh-30">体质检测</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 楼易优势 -->
        <div class="fgx-60 bjys-fff"></div>
        <div class="bjys-fff nbj-sx40 wow a-fadeinB">
          <div class="w1200 spjz">
            <div class="spbj ys-4d lddq">
              <div class="w30 h-160 bjys-e8">
                <div class="nbj-50-35 h-60 lh-30 zt18">
                  楼易提供定制化、跟踪化的个人健康调理方案
                </div>
                <div class="wbj-s-130 wbj-zy30 nbj-5 wh-55 tp100">
                  <img src="/public/static/index/img/ai10.png" />
                </div>
              </div>
              <div class="w30 h-160 bjys-e8">
                <div class="nbj-50-35 h-60 lh-30 zt18">
                  真正实现【算病、防病、治病】
                </div>
                <div class="wbj-s-130 wbj-zy30 nbj-5 wh-55 tp100">
                  <img src="/public/static/index/img/ai11.png" />
                </div>
              </div>
              <div class="w30 h-160 bjys-e8">
                <div class="nbj-50-35 h-60 lh-30 zt18">用楼易，健康更容易</div>
                <div class="wbj-s-130 wbj-zy30 nbj-5 wh-55 tp100">
                  <img src="/public/static/index/img/ai12.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="fgx-50 bjys-fff"></div>

        <!-- 精选文章 -->

        <div class="bjys-e8 nbj-sx40 wow a-fadeinB" data-wow-delay="0.2s">
          <div class="w1200 spjz">
            <div class="spbj lddq">
              <div class="w58 h-200 bjys-e8">
                <div class="nbj-s10 lh-30 zt26 ys-c2 ztjc qzdx">
                  Weather medicine
                </div>
                <div class="lh-30 zt18 ys-666 ztjc qzdx">五运六气天气医学</div>
                <div class="fgx-3 bjys-0a w-30"></div>
                <div class="nbj-sx10 ys-4d">
                  <div class="nbj-sx5">
                    [五运六气]学说，简称为[运气学]，是古人研究气候变化与疾病关系的一门学问。其包含古代天文学、气象学、物候学、地理学，属于天气医学和时间医学的范畴，是世界上最早也最完整的天气医学。
                  </div>
                  <div class="nbj-sx5">
                    木、火、土、金、水五气所形成的运称为五运（木运、火运、土运、金运、水运），风、热、湿、相火、燥、寒称为六气（三阴三阳之气）。运与气又有主、客之分，推算时需互相参照，并综合当年物候才能准确推估疾病的发病规律。
                  </div>
                  <div class="nbj-sx5">
                    运用阴阳五行生克制化理论，以天干地支时间系统进行演绎，总结人在宇宙中的生理、病理变化。
                  </div>
                </div>
              </div>
              <div class="w30 h-200 bjys-e8">
                <img
                  src="/public/static/index/img/ai-new-1.png"
                  style="height: 390px"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="fgx-120 bjys-fff"></div>

        <!-- 易经医学 -->
        <div class="qzhh wow a-fadeinB" data-wow-delay="0.5s">
          <div
            class="tlxq"
            style="
              background: #5e5b58 url(/public/static/index/img/ai-new-2.png)
                no-repeat left center;
              background-size: 85% auto;
            "
          >
            <div class="yxbg fr"></div>
          </div>
          <div class="w1200 spjz">
            <div class="spbj wbj-s-430">
              <div class="w58"></div>
              <div class="w50 nbj-sx40 z-100">
                <div class="nbj-s10 lh-30 zt26 ys-c2 ztjc qzdx">
                  Yijing<br />Medicine
                </div>
                <div class="lh-30 zt18 ys-666 ztjc qzdx">易经医学</div>
                <div class="fgx-3 bjys-0a w-30"></div>
                <div class="nbj-sx10 ys-4d">
                  <div class="nbj-sx5">
                    《易经》中记载的【物必有象】，将人体脏器的生理、病理归纳为象，即中医特有的藏象理论。中医的藏象与易经的卦象是相互对应的，这种对应关系也构成了中医的理论基础。「易有八体」，以身之八体拟八卦：干为首、坤为腹、震为足、巽为股、坎为耳、离为目、艮为手、兑为口。
                    透过藏象与卦象可以推算出个人先天的身体状况，从而得知好发疾病。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="fgx-80 bjys-fff"></div>
    <AppQRCodes />
  </div>
</template>
<script>
export default {
  mounted() {
    $(".slider5").bxSlider({
      mode: "fade",
      controls: false,
      auto: false,
      autoControls: false,
      slideMargin: 10,
      pager: false,
    });
    // 改为鼠标移上的事件只需把click改为mousemove
    $(".tab").click(function () {
      var number = $(".tab").index(this);
      $(this).addClass("checked");
      $(this).siblings().removeClass("checked");
      $(".tablelist:eq(" + number + ")").show();
      $(".tablelist:eq(" + number + ")")
        .siblings()
        .hide();
    });
    // 改为鼠标移上的事件只需把click改为mousemove
    $(".tab2").click(function () {
      var number = $(".tab2").index(this);
      $(this).addClass("checked");
      $(this).siblings().removeClass("checked");
      $(".table2list:eq(" + number + ")").show();
      $(".table2list:eq(" + number + ")")
        .siblings()
        .hide();
    });
  },
};
</script>