<template>
  <div id="main-wrap" class="main-container">
    <div>
      <!-- 轮播图 -->
      <div class="slider tp100 cd-main-content">
        <li>
          <img src="public/sliderpic/20190706/d6de79037e3ced5c1150b9280e6ab58e.jpg" />
        </li>
      </div>

      <!-- 楼易介绍 -->
      <div class="wbj-s40 wow a-fadeinB">
        <div class="nbj-sx40">
          <div class="w1200 spjz">
            <div class="czbj bjys-0a ys-fff w-200 wzjz nbj-sx10 z-100">
              <li class="zt18 lh-20">楼易介绍</li>
              <li class="zt12 lh-20">ABOUT LOUYI</li>
            </div>
            <div class="bjys-fff wbj-s-30 nbj-60">
              <div class="czbj">
                <div class="fgx-3 w-80 bjys-0a"></div>
                <div class="spbj wbj-s10">
                  <div class="czbj w85">
                    <div class="h-45 qzhh">
                      楼易信息技术(上海)有限公司，旗下拥有品牌楼易健康(Louyi
                      AI)，由楼中亮博士创办于2018
                      年3月，是一家以中医理论体系为核心，辅助以AI人工智能和大数据等技术服务，为用户提供健康....
                    </div>
                    <div class="wbj-s20">
                      <a @click="clickItem('AboutUs', 'Founder')" class="btn zt16 nbj-5-10">more>></a>
                    </div>
                  </div>
                  <!-- <div class="nbj-z30">
									<img src="/public/webpic/20190705/5cefc0dad5621ebd6cab57d6b226b35b.png" height="50px" />
								</div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 热门视频 -->
      <div class="wbj-s30 wow a-fadeinB">
        <div class="w1200 spjz wzjz">
          <div class="ys-fff zt46 ztjc qzdx">Popular Videos</div>
          <div class="ztjc wbj-s-25 zt22">热门视频</div>
          <div class="fgx-30"></div>
          <div class="fgx-1 spjz w-400 bjys-99"></div>
          <div class="wbj-s-15 qzdx">
            <li class="w-160 spjz ys-666 zt22 bjys-f2">INTRODUCE</li>
          </div>
          <div class="wzky wbj-x20">
            <li class="ztjc">
              <a @click="clickItem('Article', 'Videos')" class="ys-0a zt18">more>></a>
            </li>
          </div>
        </div>
      </div>

      <div class="wrapper wow a-fadeinB">
        <div class="section4">
          <div class="banner">
            <ul>
              <li v-for="(video, index) in homeEaghtBanners" :key="index">
                <div style="height: 100%; width: 100%" v-if="video && currentVideo == video.id"
                  v-html="getLang(video,'content')">
                </div>
              </li>
            </ul>

            <ol class="dots">
              <li v-for="(video, index) in homeEaghtBanners" :key="index">
                <div v-if="video" class="dot czbj qzdx" :class="currentVideo == video.id ? 'active' : ''"
                  @click="currentVideo = video.id">
                  <span class="zt18">{{ getLang(video, "title") }}</span>
                  <span class="zt10 ys-be">{{ getLang(video, "description") }}</span>
                </div>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <!-- 精选文章 -->
    <div class="fgx-30"></div>
    <div class="wbj-s30 wow a-fadeinB">
      <div class="w1200 spjz wzjz">
        <div class="ys-fff zt46 ztjc qzdx">Selected articles</div>
        <div class="ztjc wbj-s-25 zt22">精选文章</div>
        <div class="fgx-30"></div>
        <div class="fgx-1 spjz w-400 bjys-99"></div>
        <div class="wbj-s-15 qzdx">
          <li class="w-160 spjz ys-666 zt22 bjys-f2">INTRODUCE</li>
        </div>
        <div class="wzky wbj-x20">
          <li class="ztjc">
            <a @click="clickItem('Article', 'Articles')" class="ys-0a zt18">more>></a>
          </li>
        </div>
      </div>
    </div>

    <div class="wbj-x20 jxwz wow a-fadeinB">
      <div class="slider2">
        <div v-for="article in popularFeatured" :key="article.id" class="xsyd czbj bk">
          <a @click="clickArticle(article)">
            <div class="wbj-s20 tp-h300">
              <img :src="article.preview_img" />
            </div>
            <div class="bjys-fff nbj-5-10 nbj-x10">
              <div class="zt16 ztjc lh-30">
                {{ getLang(article,'title') }}
              </div>
              <div class="fgx-3 w-20 bjys-0a"></div>
              <div class="ys-666 h-30 lh-30 qzhh">
                {{ getLang(article,'description') }}
              </div>
              <div class="spbj lddq nbj-s10">
                <li class="ys-666">{{article.timestamp}}</li>
                <li class="ys-0a ztjc">more>></li>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>

    <!-- 品牌故事 -->
    <div class="qzhh wow a-fadeinB">
      <div class="wbj-s48">
        <div class="tlxq" style="
            background: #5e5b58
              url(/public/webpic/20190705/ee5285d6247803aaf2795c83fa995e2c.jpg)
              no-repeat bottom;
            background-size: 100% 100%;
          ">
          <div class="w1200 spjz bjhh">
            <div class="story">
              <h2>创始人介绍</h2>
              <div class="lh-20 ys-fff">
                <p>
                  楼中亮——台湾著名中医师，曾获台湾中国医药大学学士学位、南京中医药大学中医养生康复学科博士学位&nbsp;
                </p>
                <p>
                  现任台湾中医预防保健协会创会理事长、台湾周易文化研究会理事。&nbsp;
                </p>
                <p>
                  历经二十多年的潜心研究和实践，将其独创的中医预防保健系统结合古中医疗法整合成一套「治未病」的诊断系统。
                </p>
              </div>
              <div class="fgx-50"></div>
              <a @click="clickItem('AboutUs', 'Founder')" class="btn-redborder-white zt18 ztjc nbj-10-30">more>></a>
            </div>
            <div class="opacitybg"></div>
            <div class="fr tp100 w-450">
              <img src="public/webpic/20190827/0ba8d2723aa281e91a29ef3e6bb3faf2.png" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 服务 -->
    <div class="wbj-s60 wow a-fadeinB">
      <div class="w1200 spjz wzjz">
        <div class="ys-fff zt46 ztjc qzdx">our service</div>
        <div class="ztjc wbj-s-25 zt22">我们的服务</div>
        <div class="fgx-30"></div>
        <div class="fgx-1 spjz w-400 bjys-99"></div>
        <div class="wbj-s-15 qzdx">
          <li class="w-160 spjz ys-666 zt22 bjys-f2">INTRODUCE</li>
        </div>
        <div class="wzky wbj-x20">
          <li class="ztjc">
            <a @click="clickItem('AITest')" class="ys-0a zt18">more>></a>
          </li>
        </div>
      </div>
    </div>
    <div class="wbj-x40 w1200 spjz wow a-fadeinB">
      <div class="spbj lddq bjhh wbj-sx40">
        <div class="xsyd czbj w31">
          <a @click="clickItem('AITest')">
            <div class="fw">
              <div class="tp100">
                <img src="public/articlepic/20190706/467ffc640e6de7dff95bf931a05156ac.png" class="spjz wbj-s60" />
              </div>
              <div class="bjys-fff nbj-5-10 nbj-x10">
                <div class="zt18 ztjc lh-50 wzjz">算病</div>
                <div class="ys-666 h-60 lh-30 qzhh wzjz nbj-zy30 wbj-x20">
                  《易经》、五运六气天气医学、AI大数据相结合推算
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="xsyd czbj w31">
          <a @click="clickItem('AITest')">
            <div class="fw">
              <div class="tp100">
                <img src="public/articlepic/20190706/168b48c607c441b5897aba588255b346.png" class="spjz wbj-s60" />
              </div>
              <div class="bjys-fff nbj-5-10 nbj-x10">
                <div class="zt18 ztjc lh-50 wzjz">防病</div>
                <div class="ys-666 h-60 lh-30 qzhh wzjz nbj-zy30 wbj-x20">
                  个人完整健康调理报告，提出每周健康建议。
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="xsyd czbj w31">
          <a @click="clickItem('AITest')">
            <div class="fw">
              <div class="tp100">
                <img src="public/articlepic/20190706/9afd0d578240677e39865f1eb46575e9.png" class="spjz wbj-s60" />
              </div>
              <div class="bjys-fff nbj-5-10 nbj-x10">
                <div class="zt18 ztjc lh-50 wzjz">治病</div>
                <div class="ys-666 h-60 lh-30 qzhh wzjz nbj-zy30 wbj-x20">
                  《掌纹全息疗法》与《天人合一易经医学》结合治疗
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <AppQRCodes />
  </div>
</template>
<script>
import ArticleApiHelper from "~/resources/js/script/Utility/ArticleApiHelper";
export default {
  beforeCreate() {
    if (this.$route.name != "Home") {
      this.$router.replace({ name: "Home" });
    }
  },
  mounted() {
    $(".slider").bxSlider({
      mode: "fade",
      controls: false,
      auto: true,
      autoControls: true,
      slideMargin: 10,
    });
    this.fetchSpecialArticles();
    this.updateSlider();
  },
  updated() {},
  data() {
    return {
      specialArticles: [],
      currentVideo: "",
    };
  },
  computed: {
    louyiai_url() {
      return process.env.MIX_LOUYIAI_LINK;
    },
    articles() {
      return copy(this.$store.state.articles);
    },
    popularFeatured() {
      const allowCategories = ["featured", "beauty", "healthy"];
      const popularArticles = this.$store.state.articles.filter(
        (article) => allowCategories.indexOf(article.category) >= 0
      );
      return popularArticles.slice(0, 10);
    },
    homeEaghtBanners() {
      let articles = [];
      let maxCount = 8;
      for (let i = 0; i < maxCount; i++) {
        let article = this.findArticle("home_banner", i);
        let tw_match = [];
        let cn_match = [];
        if (article) {
          if (article.content) {
            tw_match = article.content.match(
              /(<iframe[\s\S]*<\/iframe>)|(<video[\s\S]*<\/video>)/
            );
          }
          if (article.cn_content) {
            cn_match = article.cn_content.match(
              /(<iframe[\s\S]*<\/iframe>)|(<video[\s\S]*<\/video>)/
            );
          }

          if (tw_match && tw_match.length > 0) {
            article.content = tw_match[0];
          }
          if (cn_match && cn_match.length > 0) {
            article.cn_content = cn_match[0];
          }
          articles.push(article);
        }
      }
      if (!this.currentVideo && articles[0]) {
        this.currentVideo = articles[0].id;
      }
      return articles;
    },
  },
  methods: {
    //取得語系內容
    getLang(row, type) {
      return getArticleLang(row, type);
    },
    findArticle(findPosition, findIndex) {
      let article = null;
      const indexInfo = this.specialArticles.find(
        (articleInfo) =>
          articleInfo.page_position == findPosition &&
          articleInfo.index == findIndex
      );
      if (indexInfo) {
        article = this.articles.find(
          (article) => article.id == indexInfo.article_id
        );
      }
      return article;
    },
    fetchSpecialArticles() {
      this.specialArticles = [];
      ArticleApiHelper.getHomeSetting()
        .then((result) => {
          this.specialArticles = result.data;
          loading(false);
        })
        .catch(errorHandler);
    },
    updateSlider() {
      if (this.articles.length == 0) {
        return;
      }
      var w = document.documentElement.clientWidth || document.body.clientWidth;
      if (w < 640) {
        $(".slider2").bxSlider({
          auto: false,
          pager: true,
        });
      } else {
        $(".slider2").bxSlider({
          slideWidth: 285,
          minSlides: 1,
          maxSlides: 4,
          slideMargin: 20,
          pager: true,
          moveSlides: 1,
        });
      }
    },
    clickArticle(article) {
      this.$router.push({
        name: "ArticleDetail",
        params: {
          id: article.id,
          article,
        },
      });
    },
    clickItem(id, moveToID) {
      this.$router.push({
        name: id,
        params: {
          moveToID,
        },
      });
    },
  },
  watch: {
    "$router.name"() {
      $(".slider").bxSlider({
        mode: "fade",
        controls: false,
        auto: true,
        autoControls: true,
        slideMargin: 10,
      });
    },
    articles: {
      handler() {
        setTimeout(() => {
          this.updateSlider();
        }, 100);
      },
      deep: true,
    },
  },
};
</script>
<style type="text/css">
.gdyc {
  position: fixed;
  top: 30%;
  right: 2px;
  z-index: 100;
}

.bkyy {
  box-shadow: 0px 0px 5px #ccc;
}

.dorp {
  position: relative;
  display: inline-block;
}

.dorp_con {
  display: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 0px 5px #ccc;
  border-radius: 5px;
  margin-top: 0px;
}

.dorp:hover .dorp1_con {
  display: block;
}
.jxwz .bx-wrapper .bx-pager {
  position: relative;
}
</style>
