const md5 = require('md5');
const common = {
    setCookie(cname, cvalue) {
        switch (typeof cvalue) {
            case "object":
                cvalue = JSON.stringify(cvalue);
                break;
        }
        var exdays = 3;
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },

    getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                let target = c.substring(name.length, c.length);
                try {
                    target = JSON.parse(target);
                } catch (error) {
                }
                return target;
            }
        }

        return "";
    },
    setLocalStorage(key, item) {
         switch (typeof item) {
            case "object":
                item = JSON.stringify(item);
                break;
        }
        localStorage.setItem(key, item);
    },
    getLocalStorage(key) {
        const value = localStorage.getItem(key);
        try {
            return JSON.parse(value);
         } catch (e) {
            return value;
        }
    },
    bindProcess(process) {
        this.process = process;
    },
    log(text) {
        if (process.env.MIX_APP_DEBUG == "true") {
            console.debug(text);
        }
        // console.debug(text);
    },
    getQueryUrl(key) {
        const params = {};
        location.search.split(/[?&]/).filter((item) => item).forEach((item) => {
            const splitItem = item.split('=');
            params[splitItem[0]] = splitItem[1];
        });
        return params[key] ? decodeURIComponent(params[key]) : null;
    },
    isWebview() {
        var useragent = navigator.userAgent;
        var rules = ['WebView','(iPhone|iPod|iPad)(?!.*Safari\/)','Android.*(wv|\.0\.0\.0)'];
        var regex = new RegExp(`(${rules.join('|')})`, 'ig');
        return Boolean(useragent.match(regex));
    },
    md5(s) {
        return md5(`royi${s}shop`);
    },
    compare(obj1, obj2) {
        let isSame = true;
        if (typeof obj1 == 'object') {
            const keys = Object.keys(obj1);
            const objectKeys = Object.keys(obj2);
            keys.find((key) => {
                if (objectKeys.indexOf(key) >= 0) {
                    const type = typeof obj1[key];
                    if (type == 'object') {
                        isSame = compare(obj1[key], obj2[key]);
                    } else {
                        isSame = obj1[key] == obj2[key];
                    }
                } else {
                    isSame = false;
                }
                return !isSame;
            });
        } else {
            isSame = obj1 == obj2
        }

        return isSame;
    },
    copy(object) {
        if (object !== null && object !== undefined) {
            if (object instanceof Array) {
                const newArray = [];
                object.forEach((item) => {
                    newArray.push(copy(item));
                })
                return newArray;
            } else if (object instanceof Date) {
                return new Date(object.getTime());
            } else if (typeof object == "object") {
                const newObj = {};
                Object.keys(object).forEach((key) => {
                    newObj[key] = copy(object[key]);
                });
                return newObj;
            }
        }
        return object;

    },
    copyTo(object, toObject = {}) {
        if (typeof object == "object") {
            Object.keys(object).forEach((key) => {
                toObject[key] = copyTo(object[key], toObject[key]);
            });
            return toObject;
        } else {
            return object;
        }
    },
    //取得文章內容
    getArticleLang(data, type) {
      let tw = "";
      let cn = "";
      let en = "";
      if (type === "title") {
        tw = data.title;
        cn = data.cn_title;
        en = data.en_title;
      } else if (type === "description") {
        tw = data.description;
        cn = data.cn_description;
        en = data.en_description;
      } else if (type === "content") {
        tw = data.content;
        cn = data.cn_content;
        en = data.en_content;
      }
      return this.getTextLang(tw, cn, en);
    },
    //取得語系
    getTextLang(tw, cn, en) {
      const lang = "cn";
      let text = "";
      let isTW = false;
      let isCN = false;
      let isEN = false;
      if (tw) isTW = true;
      if (cn) isCN = true;
      if (en) isEN = true;
      switch (lang) {
        case "tw":
          if (isTW === true) {
            text = tw;
          } else if (isCN === true) {
            text = cn;
          } else {
            text = en;
          }
          break;
        case "cn":
          if (isCN === true) {
            text = cn;
          } else if (isTW === true) {
            text = tw;
          } else {
            text = en;
          }
          break;
        case "en":
          if (isEN === true) {
            text = en;
          } else if (isTW === true) {
            text = tw;
          } else {
            text = cn;
          }
          break;
      }
      return text;
    },
    /**
     * 取得物件有修改的內容
     * @param {Object} originObj 原物件
     * @param {Object} newObj 新物件
     * @param {Array} ignoreKeys 忽略的key
     * @returns {Object}
     * @example
     * originObj = {a: 123, b: 456, c: 789}
     * newObj = {a: 111, b: 456, c: 666}
     * getChangedItems(originObj, newObj, ["c"]);
     * return {a: 111}
     */
    getChangedItems(originObj, newObj, ignoreKeys = []) {
        const update = {};
        Object.keys(newObj).forEach((column) => {
            const newValue = newObj[column];
            const originValue = originObj[column];
            if (typeof newValue == "string") {
                //字串比對
                if (newValue != originValue && ignoreKeys.indexOf(column) < 0) {
                    update[column] = newValue;
                }
            } else if (newValue instanceof Array) {
                // 陣列比對
                if (newValue.length != originValue.length) {
                    // 比對陣列長度: 如果長度不同, 即有更新
                    update[column] = newValue;
                } else if (newValue.find((value) => originValue.indexOf(value) < 0)) {
                    // 比對內容: 如果新陣列值不在原陣列內, 即有更新
                    update[column] = newValue;
                }

            }
        });
        return update;
    },
    /**
     * 取得有資料的內容
     * @param {Object} originObj 原物件
     * @param {Object} newObj 新物件
     * @param {Array} ignoreKeys 忽略的key
     * @returns {Object}
     * @example
     * originObj = {a: 123, b: 456, c: 789}
     * newObj = {a: 111, b: 456, c: 666}
     * getChangedItems(originObj, newObj, ["c"]);
     * return {a: 111}
     */
    getNotEmptyItems(originObj) {
        Object.keys(originObj).forEach((column) => {
            if (typeof originObj[column] == null || originObj[column] == "") {
                delete originObj[column];
            }
        });
        return originObj;
    }
}
Object.assign(window, common);

Date.prototype.format = function () { //author: meizz
    var d = this,
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}
