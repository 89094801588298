<template>
  <!-- 底部 -->
  <div class="bjys-99 nbj-sx30 ys-fff">
    <div class="w1200 spjz spbj lddq">
      <div class="spbj lddq">
        <div class="czbj w58">
          <div class="spbj lddq">
            <div class="czbj sjyc">
              <li class="zt18 nbj-x20">上海楼易</li>
              <a @click="clickItem('JoinUs')" class="ys-fff">
                <li>加入我们</li>
              </a>
              <a @click="clickItem('Cooperation')" class="ys-fff">
                <li>招商合作</li>
              </a>
              <a @click="clickItem('AboutUs')" class="ys-fff">
                <li>关于楼易</li>
              </a>
            </div>
            <!-- <div class="czbj">
								<li class="zt18 nbj-x20">观点</li>
								<a href="/index/article/index.html" class="ys-fff"><li>楼易动态</li></a>
								
							</div> -->
            <!-- <div class="czbj">
								<li class="zt18 nbj-x20">联系</li>
								<li>+86(21)62520133</li>
								<li>+886(4)22546199</li>
							</div> -->
            <div class="czbj sjyc">
              <li class="zt18 nbj-x20">相关公司</li>
              <li>香港商天讯国际控股集团（总公司）</li>
              <li>楼易健康科技股份有限公司（台湾分公司）</li>
              <!-- <a href="楼易健康科技股份有限公司（台湾分公司）" class="ys-fff"><li>香港商天讯国际控股集团（总公司）</li></a>
								<a href="香港商天讯国际控股集团（总公司）" class="ys-fff"><li>楼易资讯技术股份有限公司（台湾分公司）</li></a> -->
            </div>
            <div class="czbj w45">
              <li class="zt18 nbj-x20">联系楼易</li>
              <li>+86(21)62520133</li>
              <!-- <li>楼易信息技术(上海)有限公司</li> -->
              <li>上海市静安区共和路219号企业公馆2203A室</li>
            </div>
          </div>

          <div class="spbj wbj-s20 bjhh qzdx">
            <li class="zt12">©2019-2025</li>
            <li class="nbj-zy10 zt12">楼易信息技术(上海)有限公司</li>
            <li class="zt12"><a href="https://beian.miit.gov.cn/#/Integrated/index">沪ICP备19015879号-1</a></li>
            <!-- <li class="nbj-zy10">增值电信业务经营许可证：</li> -->
            <!-- <li class="zt12"><img src="/public/static/index/img/police.png"></li> -->
            <!-- <div style="width:300px;margin:0 auto;">
							     <a target="_blank" href=" " style="color:white;text-decoration:none;height:20px;line-height:20px;">
							     	<img src="/public/static/index/img/police.png" style="float:left;"/>
									<li class="nbj-zy10 zt12">沪公网安备 31010602003932号</li>
								</a>
						    </div> -->
            <li class="nbj-zy10 zt12">沪公网安备 31010602003932号</li>
          </div>
        </div>

        <!-- <div class="czbj tp-120">
						<img src="/public/webpic/20200605/1aacf385bfcbe095ef103a39a855f2e5.png" class="spjz">
						<li class="lh-50 wzjz">Android下载二维码</li>
					</div>
					<div class="czbj tp-120">
						<img src="/public/webpic/20200605/700dc0635b666222363dbba49491d0c2.png" class="spjz">
						<li class="lh-50 wzjz">AppStore下载二维码</li>
					</div>
					<div class="czbj tp-120">
						<img src="/public/webpic/20200605/80db9e10cde0fa562201feb761d7850c.jpg">
						<li class="lh-50 wzjz">小程序二维码</li>
					</div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    clickItem(pageName) {
      this.$router.push({ name: pageName });
    },
  },
};
</script>