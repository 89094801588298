<template>
  <div>
    <!-- 轮播图 -->
    <div class="slider5 tp100 cd-main-content">
      <li>
        <img
          src="/public/webpic/20190916/af52bc9262f769b6868745c788e2b2bc.jpg"
        />
      </li>
    </div>

    <div class="bjys-fff">
      <!-- 个人资料 -->
      <div class="bjys-fff wow a-fadeinB">
        <div class="fgx-20"></div>
        <div class="w1200 spjz">
          <div class="spbj lddq nbj-sx40">
            <div class="w48 tp100">
              <img
                src="/public/webpic/20190916/3dac87863a6fc54c1e0a18e2caada928.png"
                style="height: 100%"
              />
            </div>
            <div class="w50">
              <div class="ys-be nbj-x10">
                <div class="qzhh zt26 ztjc nbj-sx5">DATA</div>
                <div class="ys-4d zt24 ztjc">会员资料</div>
              </div>
              <div class="fgx-3 bjys-0a w-30"></div>
              <div class="nbj-sx10 spbj">
                <div class="">姓名：汪晓悦</div>
                <div class="nbj-z30">生日: 1985/11/10 3时</div>
              </div>
              <div class="nbj-sx10">
                <div class="nbj-sx5 lh-20">
                  <span class="zt12 lh-20">体质痰湿：</span>
                  <span class="zt12 lh-20 ys-aa"
                    >中医讲的痰不光指咳嗽时咳出来的那种痰，中医专有名词称为「痰饮」。「痰J是水分少到几乎快没有，黏黏的不太会动,会黏在组织.上的那种;「饮」则是比正常水分略稠一些但还是有流动性,不致于完全黏住不动。中医认为人体全身都有可能产生痰,而且会在人体中最脆弱、衰落、较少生命能量(气)的部位。当身体受伤或受到某种程度的刺激时,局部的结缔组织，也会分泌黏性的多糖体而形成痰。当人体老化时，身体尤其容易受到刺激而生痰。其发生的部位往往就是容易聚湿之处。痰与湿二者互为作用,因此关系密切,形同雪上加霜。</span
                  >
                </div>
                <div class="nbj-sx5 lh-20">
                  <span class="zt12 lh-20">症状：</span>
                  <span class="zt12 lh-20 ys-aa">肥胖、贪睡、油腻</span>
                </div>
                <div class="nbj-sx5 lh-20">
                  <span class="zt12 lh-20">注意事项：</span>
                  <span class="zt12 lh-20 ys-aa">家里可以放除湿机</span>
                </div>
                <div class="nbj-sx5 lh-20">
                  <span class="zt12 lh-20">疾病：</span>
                  <span class="zt12 lh-20 ys-aa">神经官能症、糖尿病</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="fgx-20"></div>
      </div>
    </div>

    <!-- 我的调养方 -->
    <div class="wbj-s30 wow a-fadeinB" id="tyf">
      <div class="fgx-30"></div>
      <div class="w1200 spjz wzjz">
        <div class="ys-fff zt46 ztjc qzdx">Selected articles</div>
        <div class="ztjc wbj-s-25 zt22">我的调养方案</div>
        <div class="fgx-30"></div>
        <div class="fgx-1 spjz w-400 bjys-99"></div>
        <div class="wbj-s-15 qzdx">
          <li class="w-160 spjz ys-666 zt22 bjys-f2">INTRODUCE</li>
        </div>
      </div>
    </div>

    <div class="w1200 spjz wow a-fadeinB">
      <div class="bjys-00">
        <div class="spbj jzdq">
          <a @click="selectedNursing = '神经官能症'"
            ><div
              class="tab zt16 ys-666"
              :class="selectedNursing == '神经官能症' ? 'checked' : ''"
            >
              神经官能症的调养方案
            </div></a
          >

          <a @click="selectedNursing = '糖尿病'"
            ><div
              class="tab zt16 ys-666"
              :class="selectedNursing == '糖尿病' ? 'checked' : ''"
            >
              糖尿病的调养方案
            </div></a
          >
        </div>
      </div>
      <div>
        <div class="tablelist spbj">
          <div class="wbj-x20 w1200 spjz">
            <div class="sliderNursing wow a-fadeinB"></div>
            <div v-show="false" ref="sliderNursingContent">
              <div
                v-for="nursing in nursingList.filter(
                  (n) => n.category == selectedNursing
                )"
                class="xsyd czbj bk"
                :key="nursing.id"
              >
                <div class="wbj-s20 tp-h300">
                  <img :src="nursing.pic" />
                </div>
                <div class="bjys-fff nbj-5-10 nbj-x10">
                  <div class="ztjc wbj-s-165 zt24 wzyy ys-fff wzjz lh-30">
                    {{ nursing.title }}
                  </div>
                  <div class="wzjz wzyy ys-fff">{{ nursing.desc }}</div>
                  <div class="ys-666 wbj-s120 nbj-zy10 h-160 lh-20 qzhh">
                    <div class="nbj-sx5 lh-20" v-html="nursing.html"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 食物宜忌汇总 -->
    <div class="wbj-s30 wow a-fadeinB" id="yjhz">
      <div class="fgx-10"></div>
      <div class="w1200 spjz wzjz">
        <div class="ys-fff zt46 ztjc qzdx">Selected articles</div>
        <div class="ztjc wbj-s-25 zt22">食物宜忌汇总</div>
        <div class="fgx-30"></div>
        <div class="fgx-1 spjz w-400 bjys-99"></div>
        <div class="wbj-s-15 qzdx">
          <li class="w-160 spjz ys-666 zt22 bjys-f2">INTRODUCE</li>
        </div>
      </div>
    </div>
    <div class="w1200 spjz">
      <div class="bjys-00">
        <div class="spbj jzdq wow a-fadeinB">
          <a @click="selectedFood = '宜吃食物'"
            ><div
              class="tab zt16 ys-666"
              :class="selectedFood == '宜吃食物' ? 'checked' : ''"
            >
              宜吃食物
            </div></a
          >

          <a @click="selectedFood = '忌吃食物'"
            ><div
              class="tab zt16 ys-666"
              :class="selectedFood == '忌吃食物' ? 'checked' : ''"
            >
              忌吃食物
            </div></a
          >
        </div>
      </div>
      <div>
        <div class="tablelist spbj">
          <div class="wbj-x20 w1200 spjz">
            <div class="spbj bjhh">
              <div
                v-for="food in foods.filter((f) => f.category == selectedFood)"
                :key="food.id"
                class="w25 czbj nbj-sx20 wow a-fadeinB"
              >
                <div class="xfyy">
                  <div class="tp-h300 tp100">
                    <img :src="food.pic" />
                  </div>
                  <div class="bjys-fff nbj-25">
                    <div class="zt16 ztjc lh-30">[{{ food.title }}]</div>
                    <div
                      class="ys-666 h-60 zt12 lh-20 qzhh"
                      v-html="food.html"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fgx-30"></div>
  </div>
</template>
<script>
export default {
  mounted() {
    // 改为鼠标移上的事件只需把click改为mousemove
    $(".tab").click(function () {
      var number = $(".tab").index(this);
      $(this).addClass("checked");
      $(this).siblings().removeClass("checked");
      $(".tablelist:eq(" + number + ")").show();
      $(".tablelist:eq(" + number + ")")
        .siblings()
        .hide();
    });
    // 改为鼠标移上的事件只需把click改为mousemove
    $(".tab").click(function () {
      var number = $(".tab").index(this);
      $(this).addClass("checked");
      $(this).siblings().removeClass("checked");
      $(".tablelist:eq(" + number + ")").show();
      $(".tablelist:eq(" + number + ")")
        .siblings()
        .hide();
    });
    $(".slider5").bxSlider({
      mode: "fade",
      controls: false,
      auto: false,
      autoControls: false,
      slideMargin: 10,
      pager: false,
    });
    this.updateSliderNursing();
  },
  data() {
    return {
      selectedNursing: "神经官能症",
      selectedFood: "宜吃食物",
      nursingList: [
        {
          id: 66,
          title: "食疗调养",
          text: "材料：当归10公克，紫丹参12公克，白檀香5公克，細砂仁3公克，酸吏仁6公克，炙近志6公克，北五味5公克，玉桔梗6公克，燬牡蛎12公克。制法：将以上材料以水煎服用,日服2次。",
          create_time: "1562012910",
          status: 1,
          html: '<div class="nbj-sx5 lh-20">材料：当归10公克，紫丹参12公克，白檀香5公克，細砂仁3公克，酸吏仁6公克，炙近志6公克，北五味5公克，玉桔梗6公克，燬牡蛎12公克。</div><p><span>制法</span>：将以上材料以水煎服用,日服2次。</p>',
          pid: 5,
          pic: "/public/tiaoyangpic/20190916/39b8491ad3abd6400033c5f4f754f9d6.jpg",
          key: "食疗调养|食疗调养|食疗调养",
          desc: "益心定智汤",
          top: 1,
          paix: 1,
          statusname: "已通过",
          category: "神经官能症",
        },
        {
          id: 67,
          title: "穴道调养",
          text: "位置：太冲穴位于脚背上大拇指和食指间的夹缝，往后数约三指宽的地方。方法：睡前按摩可排解压力、放松,避免失眠。",
          create_time: "1562012982",
          status: 1,
          html: '<div class="nbj-sx5 lh-20">位置：太冲穴位于脚背上大拇指和食指间的夹缝，往后数约三指宽的地方。</div><div class="nbj-sx5 lh-20">方法：睡前按摩可排解压力、放松,避免失眠。</div>',
          pid: 5,
          pic: "/public/tiaoyangpic/20190916/bc2567091719fe46615469dc86166eef.jpg",
          key: "穴道调养|穴道调养|穴道调养",
          desc: "按摩太冲穴",
          top: 1,
          paix: 2,
          statusname: "已通过",
          category: "神经官能症",
        },
        {
          id: 68,
          title: "茶饮调养",
          text: "材料：柴胡4公克、百合8公克、麦门冬8公克、甘草2片。制法：将所有材料放入600c.c. 的水中，大火煮滚15分钟，去渣即可饮用，一天1至2杯。",
          create_time: "1562396228",
          status: 1,
          html: '<div class="nbj-sx5 lh-20">材料：柴胡4公克、百合8公克、麦门冬8公克、甘草2片。</div><p><span>制法</span>：将所有材料放入600c.c. 的水中，大火煮滚15分钟，去渣即可饮用，一天1至2杯。</p>',
          pid: 5,
          pic: "/public/tiaoyangpic/20190916/dfb1d29a2752961b8dbead8a08687867.jpg",
          key: "",
          desc: "柴胡百合茶",
          top: 1,
          paix: 3,
          statusname: "已通过",
          category: "神经官能症",
        },
      ],
      foods: [
        {
          id: 68,
          title: "五谷根茎",
          text: "薏仁、白萝卜、山药、莲藕",
          create_time: "1562013942",
          status: 1,
          html: "<p><span>薏仁、白萝卜、山药、莲藕</span></p>",
          pid: 7,
          pic: "/public/yijipic/20190916/1a80a9b54c3bc574b7a670e214cc202a.jpg",
          key: "",
          desc: "",
          top: 1,
          paix: 1,
          statusname: "已通过",
          category: "宜吃食物",
        },
        {
          id: 69,
          title: "蔬菜",
          text: "荸著、紫菜、海带、香菇、高丽菜、冬瓜、韭菜、芥菜",
          create_time: "1562013990",
          status: 1,
          html: "<p><span>荸著、紫菜、海带、香菇、高丽菜、冬瓜、韭菜、芥菜</span></p>",
          pid: 7,
          pic: "/public/yijipic/20190916/fa65e6a358284fc6f8186314a29200f6.jpg",
          key: "",
          desc: "",
          top: 1,
          paix: 2,
          statusname: "已通过",
          category: "宜吃食物",
        },
        {
          id: 70,
          title: "海鲜",
          text: "鲢鱼、鳟鱼、带鱼、石鲷、泥鳅、黄鳝、海参、大闸蟹、海蜇、石姥鱼、赤綜",
          create_time: "1562427504",
          status: 1,
          html: "<p><span>鲢鱼、鳟鱼、带鱼、石鲷、泥鳅、黄鳝、海参、大闸蟹、海蜇、石姥鱼、赤綜</span></p>",
          pid: 7,
          pic: "/public/yijipic/20190916/958599907ca4695f2538edc17b369947.jpg",
          key: "",
          desc: "",
          top: 0,
          paix: 3,
          statusname: "已通过",
          category: "宜吃食物",
        },
        {
          id: 71,
          title: "豆类",
          text: "扁豆、红豆、黑豆、红小豆、蚕豆、芸豆、黄豆、绿豆",
          create_time: "1562427619",
          status: 1,
          html: "<p><span>扁豆、红豆、黑豆、红小豆、蚕豆、芸豆、黄豆、绿豆</span></p>",
          pid: 7,
          pic: "/public/yijipic/20190916/1a40ab2909dc6f13a39a41b2d55a4440.jpg",
          key: "",
          desc: "",
          top: 0,
          paix: 4,
          statusname: "已通过",
          category: "宜吃食物",
        },
        {
          id: 72,
          title: "肉类",
          text: "牛肉、羊肉、鸡肉",
          create_time: "1562427649",
          status: 1,
          html: "<p><span>牛肉、羊肉、鸡肉</span></p>",
          pid: 7,
          pic: "/public/yijipic/20190916/cdd30af72de101aa516a6d83819c25fe.png",
          key: "",
          desc: "",
          top: 0,
          paix: 5,
          statusname: "已通过",
          category: "宜吃食物",
        },
        {
          id: 73,
          title: "水果及干货",
          text: "荔枝、樱桃、杏仁、白果、佛手柑、柠檬、槟榔、栗子",
          create_time: "1562427672",
          status: 1,
          html: "<p><span>荔枝、樱桃、杏仁、白果、佛手柑、柠檬、槟榔、栗子</span></p>",
          pid: 7,
          pic: "/public/yijipic/20190916/e55df4015c1b5c426f0977c1c5011b32.jpg",
          key: "",
          desc: "",
          top: 0,
          paix: 6,
          statusname: "已通过",
          category: "宜吃食物",
        },
        {
          id: 74,
          title: "其他",
          text: "葱、辣椒、大蒜、香椿、生姜",
          create_time: "1562427696",
          status: 1,
          html: "<p><span>葱、辣椒、大蒜、香椿、生姜</span></p>",
          pid: 7,
          pic: "/public/yijipic/20190916/176788ab49a73aa502e8d2d64b13c499.jpg",
          key: "",
          desc: "",
          top: 0,
          paix: 7,
          statusname: "已通过",
          category: "宜吃食物",
        },
        {
          id: 75,
          title: "蔬菜",
          text: "甜菜",
          create_time: "1563505122",
          status: 1,
          html: "<p><span>甜菜</span></p>",
          pid: 8,
          pic: "/public/yijipic/20190916/53bc7a984ba9f012a214956ae09982b6.jpg",
          key: "",
          desc: "",
          top: 0,
          paix: 0,
          statusname: "已通过",
          category: "忌吃食物",
        },
        {
          id: 76,
          title: "海鲜",
          text: "带壳海鲜(如︰虾、蟹、贝类、蚌肉、牡蛎)",
          create_time: "1563505204",
          status: 1,
          html: "<p>带壳海鲜(如︰虾、蟹、贝类、蚌肉、牡蛎)</p>",
          pid: 8,
          pic: "/public/yijipic/20190916/7c4f47d07a48a40f72218be9adc3b64d.jpg",
          key: "",
          desc: "",
          top: 0,
          paix: 0,
          statusname: "已通过",
          category: "忌吃食物",
        },
        {
          id: 77,
          title: "肉类",
          text: "鸭肉",
          create_time: "1563505333",
          status: 1,
          html: "<p>鸭肉</p>",
          pid: 8,
          pic: "/public/yijipic/20190916/c226ac218da6433480e63c08171d9148.png",
          key: "",
          desc: "",
          top: 0,
          paix: 0,
          statusname: "已通过",
          category: "忌吃食物",
        },
        {
          id: 78,
          title: "水果及干货",
          text: "柚子、枇杷、西瓜、石榴、杨梅、山楂",
          create_time: "1563505365",
          status: 1,
          html: "<p>柚子、枇杷、西瓜、石榴、杨梅、山楂</p>",
          pid: 8,
          pic: "/public/yijipic/20190916/937f0837795ee83bbf1c9d36219ec48d.jpg",
          key: "",
          desc: "",
          top: 0,
          paix: 0,
          statusname: "已通过",
          category: "忌吃食物",
        },
        {
          id: 79,
          title: "其他",
          text: "枸杞",
          create_time: "1563505394",
          status: 1,
          html: "<p>枸杞</p>",
          pid: 8,
          pic: "/public/yijipic/20190916/0429057f3e5024f41bdeeff6040d4d55.jpg",
          key: "",
          desc: "",
          top: 0,
          paix: 0,
          statusname: "已通过",
          category: "忌吃食物",
        },
      ],
    };
  },
  methods: {
    updateSliderNursing() {
      setTimeout(() => {
        $(".sliderNursing").children().remove();
        const slider = $("<div class='wow a-fadeinB'></div>");
        slider.html(this.$refs["sliderNursingContent"].innerHTML);
        $(".sliderNursing").append(slider);
        slider.bxSlider({
          slideWidth: 385,
          minSlides: 1,
          maxSlides: 3,
          slideMargin: 20,
          pager: true,
          moveSlides: 1,
        });
      }, 100);
    },
  },
  watch: {
    selectedNursing() {
      this.updateSliderNursing();
    },
  },
};
</script>
<style type="text/css">
.bx-wrapper .bx-pager {
  position: relative;
}
</style>