<template>
  <div v-html="page.content"></div>
</template>
<script>
export default {
  props: {
    page: {
      type: Object,
      default() {
        return {
          id: "",
          content: "",
          timestemp: "",
        };
      },
    },
  },
  mounted() {
    if (!this.page.id) {
      this.setPage();
    }
  },
  methods: {
    setPage() {
      if (!this.$store.state.pages.length) {
        return;
      }
      this.$store.state.pages.find((page) => {
        if (page.id == this.page_name) {
          Object.assign(this.page, page);
        }
        return page.id == this.page_name;
      });
    },
  },
  computed: {
    page_name() {
      return this.$route.meta.page_name;
    },
  },
  watch: {
    "$store.state.pages"(value) {
      this.setPage();
    },
    $route(value) {
      this.setPage();
    },
  },
};
</script>