<template>
  <div>
    <div>
      <Banner />
      <div class="w1200 spjz nbj-sx40">
        <div class="spbj lddq">
          <div class="autoMenu bkyy wow a-fadeinB" id="autoMenu" data-autoMenu></div>

          <div class="w-900 nbj-sx40 bjys-fff bkyy fr wow a-fadeinB" data-wow-delay="0.2s" id="w-900">
            <div class="nbj-25 wow a-fadeinB">
              <div class="zbk-007 z-100 tp-33 spbj">
                <h5 class="bjys-e8">最新消息</h5>
                <img src="/public/static/index/img/xsj.png" />
              </div>
              <div class="fgx-2 bjys-e8 wbj-s-2"></div>
              <div class="nbj-sx20 nr" v-html="content"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AppQRCodes />
  </div>
</template>
<script>
import autoMenu from "/public/static/index/js/jquery.autoMenu.js";
export default {
  mounted() {
    this.updateDoms();
  },
  methods: {
    updateDoms() {
      autoMenu();
      $(".slider5").bxSlider({
        mode: "fade",
        controls: false,
        auto: false,
        autoControls: false,
        slideMargin: 10,
        pager: false,
      });
      $(window).scroll(function () {
        var scrolls = $(window).scrollTop();
        if (scrolls > 560) {
          $("#autoMenu").addClass("gylygd");
          $("#w-900").addClass("wbj-z300");
        } else {
          $("#autoMenu").removeClass("gylygd");
          $("#w-900").removeClass("wbj-z300");
        }
      });
    },
  },
  computed: {
    content() {
      const article = this.$store.state.articles.find(
        (article) => article.category == "news"
      );
      let content = "";
      if (article) {
        if (article.cn_content) {
          content = article.cn_content;
        } else {
          content = article.content;
        }
      }
      return content;
    },
  },
  watch: {
    $route() {
      this.updateDoms();
    },
  },
};
</script>
<style type="text/css">
.w-900 {
  width: 920px;
}
.wbj-z300 {
  margin-left: 280px;
}
.bkyy {
  box-shadow: 0px 0px 5px #cccc;
}
.autoMenu {
  height: 70px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.autoMenu li.active {
  background: #444;
}

.autoMenu li:hover {
  background: #555;
}
.wbj-s-2 {
  margin-top: -2px;
}
.nbj-sx20.nr a {
  color: #12abee;
}
@media all and (max-width: 1024px) {
  .wbj-z300 {
    margin-left: 0px;
  }
}
</style>
